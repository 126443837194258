/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { StreamChat } from "stream-chat";
import { makeStyles } from "tss-react/mui";

import {
  APPBAR_HEIGHT,
  CLIENT_LOGIN_METHOD,
  DRAWER_BREAKPOINT,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  MAX_PAGE_WIDTH,
} from "../constants";
import {
  apiRequest,
  clearAndLogout,
  BadgeCountsContext,
  DrawerContext,
  formatApiRequestError,
  handleSessionCheck,
  redirectToLogout,
  UserContext,
} from "../helpers";
import { drawerCollapse, drawerExpansion } from "../theme";
import { ComponentPropType } from "../types";
import AppBarResponsive from "./appbar/AppBarResponsive";
import Drawer from "./Drawer";
import { useAuth } from "./hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  appBarMargin: {
    height: APPBAR_HEIGHT,
  },
  drawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      marginLeft: DRAWER_WIDTH_COLLAPSED,
      transition: drawerCollapse.transitionMargin,
    },
  },
  drawerExpanded: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      marginLeft: DRAWER_WIDTH_EXPANDED,
      transition: drawerExpansion.transitionMargin,
    },
  },
  pageItems: {
    height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
    maxWidth: MAX_PAGE_WIDTH,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}));

const AppFrameComponent = (props: ComponentPropType) => {
  const { children } = props;
  const { isUserAuth, user } = useAuth();
  const location = useLocation();

  const [previousLocation, setPreviousLocation] = useState(location);
  const [isBrowserDrawerExpanded, setIsBrowserDrawerExpanded] = useState(true);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [badgeCounts, setBadgeCounts] = useState(0);
  const [badgeCountIntervalId, setBadgeCountIntervalId] = useState<any | null>(
    null
  );

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onMessagingPage = Boolean(
    location.pathname.match("/messaging") || location.pathname === "/"
  );

  useEffect(() => {
    if (location !== previousLocation) {
      setPreviousLocation(location);
      handleSessionCheck();
    }
  }, [location, previousLocation]);

  useEffect(() => {
    setBadgeCounts(0);
  }, [onMessagingPage]);

  useEffect(() => {
    let isBrowserDrawerExpanded =
      localStorage.getItem("isBrowserDrawerExpanded") !== "false";
    localStorage.setItem(
      "isBrowserDrawerExpanded",
      `${isBrowserDrawerExpanded}`
    );
    setIsBrowserDrawerExpanded(isBrowserDrawerExpanded);

    if (!isUserAuth) {
      redirectToLogout(navigate);
    } else {
      handleSessionCheck();
      fetchStreamUnreadCounts();
    }

    return () => {
      clearBadgeCountInterval();
    };
    // eslint-disable-next-line
  }, []);

  const handleToggleBrowserDrawer = () => {
    localStorage.setItem(
      "isBrowserDrawerExpanded",
      `${!isBrowserDrawerExpanded}`
    );
    setIsBrowserDrawerExpanded(!isBrowserDrawerExpanded);
  };

  const handleToggleMobileDrawer = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  };

  const handleLogoutUser = () => {
    apiRequest({
      method: "POST",
      endpoint: "sign-out",
      body: { method: CLIENT_LOGIN_METHOD },
    })
      .then(() => {
        clearAndLogout(navigate);
      })
      .catch((error) => {
        if (error.status === 401) {
          clearAndLogout(navigate);
        } else {
          console.warn(
            "Could not sign out user:",
            formatApiRequestError(error)
          );
          enqueueSnackbar("Could not sign out user", { variant: "error" });
        }
      });
  };

  const clearBadgeCountInterval = () => {
    if (badgeCountIntervalId) {
      clearTimeout(badgeCountIntervalId);
    }
  };

  const fetchStreamUnreadCounts = async (unreadMessages = null) => {
    let unreadCount;

    if (
      unreadMessages !== null &&
      location &&
      location.pathname &&
      location.pathname.match(/\/(messaging)?/)
    ) {
      unreadCount = unreadMessages;
    } else {
      const chatClient = StreamChat.getInstance(
        process.env.REACT_APP_STREAM_KEY || ""
      );
      unreadCount = await chatClient
        .connectUser(
          {
            id: `${user.id}`,
            name: `${user.firstName} ${user.lastName}`,
          },
          user.chatToken
        )
        .then((data: any) => {
          return data.me.total_unread_count;
        })
        .catch((error) => {
          console.error(error);
          return 0;
        })
        .finally(async () => {
          await chatClient
            .disconnectUser()
            .catch((err) => console.error("disconnect error: ", err));
        });

      clearBadgeCountInterval();
      setBadgeCountIntervalId(
        setTimeout(
          fetchStreamUnreadCounts,
          parseInt(process.env.REACT_APP_BADGE_COUNT_INTERVAL!)
        )
      );
    }
    setBadgeCounts(unreadCount);
  };

  return !isUserAuth ? (
    <CircularProgress className={classes.spinner} />
  ) : (
    <DrawerContext.Provider value={isBrowserDrawerExpanded}>
      <BadgeCountsContext.Provider
        value={{
          badgeCounts,
          refreshStreamUnreadCounts: fetchStreamUnreadCounts,
        }}
      >
        <UserContext.Provider value={{ user }}>
          <AppBarResponsive
            badgeCounts={badgeCounts}
            isBrowserDrawerExpanded={isBrowserDrawerExpanded}
            onToggleMobileDrawer={handleToggleMobileDrawer}
            onToggleBrowserDrawer={handleToggleBrowserDrawer}
            onLogoutUser={handleLogoutUser}
            {...props}
          />
          <Drawer
            badgeCounts={badgeCounts}
            isBrowserDrawerExpanded={isBrowserDrawerExpanded}
            isMobileDrawerOpen={isMobileDrawerOpen}
            onClose={handleToggleMobileDrawer}
          />
          <div className={classes.appBarMargin} />
          <main
            className={classNames(classes.pageItems, {
              [classes.drawerExpanded]: isBrowserDrawerExpanded,
              [classes.drawerCollapsed]: !isBrowserDrawerExpanded,
            })}
          >
            {children}
          </main>
        </UserContext.Provider>
      </BadgeCountsContext.Provider>
    </DrawerContext.Provider>
  );
};

const AppFrameWrapper = (Component: React.FunctionComponent) => {
  return (props: any) => (
    <AppFrameComponent {...props}>
      <Component />
    </AppFrameComponent>
  );
};

export { AppFrameWrapper };
