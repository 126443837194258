import { useRef, useState, useEffect } from "react";

import {
  CheckCircle,
  Cancel,
  Error,
  ExpandMore,
  ExpandLess,
  Stars,
} from "@mui/icons-material";
import {
  Grid,
  ImageList,
  ImageListItem,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { InspectionRating } from "../../../constants";
import { apiRequest, formatApiRequestError } from "../../../helpers";

const useStyles = makeStyles()((theme) => ({
  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonPlaceholder: {
    display: "flex",
    justifyContent: "center",
    width: "56px",
  },
  cellBorderBottom: {
    borderBottom: theme.divider.default,
  },
  cellBorderRight: {
    borderRight: theme.divider.default,
  },
  cellContent: {
    display: "flex",
    alignItems: "center",
  },
  details: {
    minHeight: "150px",
  },
  detailsBackground: {
    backgroundColor: "#f8f8f8",
  },
  green: {
    color: "#00C67F",
  },
  grey: {
    color: "#B0BEC5",
  },
  inspectionPoint: {
    fontSize: 14,
    fontWeight: 400,
    color: "rgb(51, 51, 51, 0.7)",
    fontFamily: ["OpenSans-Regular", "Open Sans"].join(","),
    minHeight: "54px",
  },
  inspectionPointComment: {
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(2),
  },
  inspectionPointName: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "@media only screen and (max-width: 320px)": {
      minWidth: "100%",
    },
  },
  loadingBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "60%",
  },
  loadingContainer: {
    position: "relative",
    textAlign: "center",
  },
  loadingSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  loadingTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  purple: {
    color: "#7E57C2",
  },
  rating: {
    display: "flex",
    justifyContent: "center",
    width: "90px",
  },
  ratingSmall: {
    margin: 0,
    paddingLeft: theme.spacing(1),
    width: "120px",
    "@media only screen and (max-width: 320px)": {
      width: "initial",
      flexGrow: 1,
    },
  },
  red: {
    color: "red",
  },
  textPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tileContainer: {
    margin: theme.spacing(2),
  },
}));

interface ComponentPropsType {
  comment?: string;
  id: number;
  isSmallView?: boolean;
  name: string;
  photos?: {
    id: number;
  }[];
  rating: InspectionRating;
}

const InspectionPointListItem = (props: ComponentPropsType) => {
  const { inspectionId } = useParams();
  const { classes } = useStyles();
  const { comment, id, isSmallView, name, photos, rating } = props;
  const getInspectionPhotosAbortController = new AbortController();
  const cardRef = useRef(null);
  const hasPhotos = photos && photos.length > 0;

  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [tiles, setTiles] = useState<any | null>(null);

  useEffect(() => {
    return () => {
      getInspectionPhotosAbortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  const handleExpansionPanel = () => {
    setOpen(!open);
    if (!loaded) {
      apiRequest({
        endpoint: `reports/inspections/${inspectionId}/points/${id}/photos`,
        params: { camel_case: true },
        signal: getInspectionPhotosAbortController.signal,
      })
        .then((response: any) => {
          setTiles(response);
          setLoaded(true);
        })
        .catch((error) => {
          if (error.status >= 400) {
            console.warn(
              `Could not fetch inspection point ${id} photos:`,
              formatApiRequestError(error)
            );
          }
        });
    }
  };

  return (
    <Grid
      className={classes.inspectionPoint}
      container
      alignItems="stretch"
      id={id.toString()}
      ref={cardRef}
    >
      <Grid
        className={classNames(
          classes.inspectionPointName,
          classes.cellContent,
          classes.cellBorderRight,
          classes.cellBorderBottom
        )}
        item
        xs
      >
        <div className={classes.textPadding}>{name}</div>
      </Grid>
      {isSmallView ? (
        <Grid
          className={classNames(
            classes.ratingSmall,
            classes.cellContent,
            classes.cellBorderRight,
            classes.cellBorderBottom
          )}
          container
          alignContent="center"
          spacing={1}
          item
        >
          {rating === InspectionRating.EXCEEDS_STANDARD && (
            <>
              <Grid
                item
                className={classNames(classes.alignCenter, classes.purple)}
              >
                <Stars />
              </Grid>
              <Grid item>{"Exceeds"}</Grid>
            </>
          )}
          {rating === InspectionRating.MEETS_STANDARD && (
            <>
              <Grid
                className={classNames(classes.alignCenter, classes.green)}
                item
              >
                <CheckCircle />
              </Grid>
              <Grid item>{"Meets"}</Grid>
            </>
          )}
          {rating === InspectionRating.BELOW_STANDARD && (
            <>
              <Grid
                item
                className={classNames(classes.alignCenter, classes.red)}
              >
                <Error />
              </Grid>
              <Grid item>{"Below"}</Grid>
            </>
          )}
          {(rating === InspectionRating.NOT_INSPECTED ||
            rating === InspectionRating.NOT_SET) && (
            <>
              <Grid
                item
                className={classNames(classes.alignCenter, classes.grey)}
              >
                <Cancel />
              </Grid>
              <Grid item>{"N/A"}</Grid>
            </>
          )}
        </Grid>
      ) : (
        <>
          <Grid
            className={classNames(
              classes.rating,
              classes.purple,
              classes.cellContent,
              classes.cellBorderRight,
              classes.cellBorderBottom
            )}
            item
          >
            {rating === InspectionRating.EXCEEDS_STANDARD && (
              <Stars fontSize="large" />
            )}
          </Grid>
          <Grid
            className={classNames(
              classes.rating,
              classes.green,
              classes.cellContent,
              classes.cellBorderRight,
              classes.cellBorderBottom
            )}
            item
          >
            {rating === InspectionRating.MEETS_STANDARD && (
              <CheckCircle fontSize="large" />
            )}
          </Grid>
          <Grid
            className={classNames(
              classes.rating,
              classes.red,
              classes.cellContent,
              classes.cellBorderRight,
              classes.cellBorderBottom
            )}
            item
          >
            {rating === InspectionRating.BELOW_STANDARD && (
              <Error fontSize="large" />
            )}
          </Grid>
          <Grid
            className={classNames(
              classes.rating,
              classes.grey,
              classes.cellContent,
              classes.cellBorderRight,
              classes.cellBorderBottom
            )}
            item
          >
            {(rating === InspectionRating.NOT_INSPECTED ||
              rating === InspectionRating.NOT_SET) && (
              <Cancel fontSize="large" />
            )}
          </Grid>
        </>
      )}
      <Grid
        className={classNames(classes.buttonPlaceholder, {
          [classes.detailsBackground]: open,
          [classes.cellBorderBottom]: !open,
        })}
        item
      >
        {(comment || hasPhotos) && (
          <IconButton aria-label="expand" onClick={handleExpansionPanel}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Grid>
      {(comment || hasPhotos) && open && (
        <Grid
          className={classNames(classes.details, classes.detailsBackground)}
          container
          item
          xs={12}
        >
          {comment && (
            <Grid
              className={classNames(classes.inspectionPointComment, {
                [classes.noBottomMargin]: hasPhotos,
              })}
              item
              xs={12}
            >
              {comment}
            </Grid>
          )}
          {hasPhotos && (
            <>
              {loaded ? (
                <Grid className={classes.tileContainer} item xs={12}>
                  <ImageList rowHeight={184} cols={isSmallView ? 1 : 3}>
                    {tiles.map((tile: any) => (
                      <Tooltip
                        key={tile.id}
                        title={
                          <img
                            src={tile.imageUrl}
                            alt={`${name} ${id}`}
                            width="100%"
                          />
                        }
                      >
                        <ImageListItem cols={1}>
                          <img
                            style={{ height: "184px" }}
                            src={tile.imageUrl}
                            alt={`${name} ${id}`}
                          />
                        </ImageListItem>
                      </Tooltip>
                    ))}
                  </ImageList>
                </Grid>
              ) : (
                <Grid
                  className={classes.loadingContainer}
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  item
                  xs={12}
                >
                  <div className={classes.loadingTitle}>
                    {"Just a moment..."}
                  </div>
                  <div className={classes.loadingSubtitle}>
                    {"We are gathering your inspection photos."}
                  </div>
                  <div className={classes.loadingBar}>
                    <LinearProgress color="secondary" />
                  </div>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default InspectionPointListItem;
