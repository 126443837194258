export const useAuth = () => {
  const userInStorage = localStorage.getItem("user");
  const userObject =
    userInStorage && userInStorage !== "undefined" ? userInStorage : "{}";
  const userObjectParsed = JSON.parse(userObject);
  const isUserAuth = userInStorage !== null;

  return {
    isUserAuth,
    user: userObjectParsed,
  };
};
