import { Grid, LinearProgress, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "tss-react/mui";

import { DRAWER_BREAKPOINT } from "../../constants";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface ComponentPropsType {
  columnsLargeLength: number;
  columnsSmallLength: number;
  subtitle: string | React.ReactNode;
  title: string | React.ReactNode;
}

const TableLoadingState = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(DRAWER_BREAKPOINT)
  );
  const { columnsLargeLength, columnsSmallLength, subtitle, title } = props;
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={isSmallScreen ? columnsSmallLength : columnsLargeLength}
      >
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={classes.container}
        >
          <Grid item xs={10} className={classes.title}>
            {title}
          </Grid>
          <Grid item xs={10} className={classes.subtitle}>
            {subtitle}
          </Grid>
          <Grid item xs={8}>
            <div className={classes.container}>
              <LinearProgress color="secondary" />
            </div>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default TableLoadingState;
