import React, { useEffect, useRef, useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import background from "../../assets/images/loginBackground.png";
import logo from "../../assets/images/sweptBlueLogo.png";
import { CLIENT_LOGIN_METHOD } from "../../constants";
import { apiRequest, formatApiRequestError } from "../../helpers";
import { validateEmailHelper } from "../../validationHelpers";
import { useAuth } from "../hooks/useAuth";
import PasswordFieldWithValidation from "../inputs/PasswordFieldWithValidation";
import TextFieldWithValidation from "../inputs/TextFieldWithValidation";

const useStyles = makeStyles()((theme: any) => ({
  root: {
    minHeight: "100vh",
    margin: "0px auto",
    paddingBottom: "10vh",
    paddingTop: "20vh",
    position: "relative",
    width: "100vw",
    justifyContent: "center",
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
      marginBottom: 8,
    },
    "@media (max-width: 600px)": {
      paddingTop: "10vh",
    },
  },
  header: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    height: 64,
    left: "auto",
    padding: "12px 0 0 12px",
    position: "fixed",
    right: 0,
    top: 0,
    width: "100%",
    zIndex: 1201,
    "& img": {
      width: 122,
    },
  },
  gridContainer: {
    width: "100%",
    zIndex: 2,
    placeContent: "start center",
  },
  featureImage: {
    padding: theme.spacing(2),
    "& img": {
      float: "right",
      margin: "0 auto",
      marginRight: "5vw",
      maxWidth: "40vw",
      "@media (max-width: 600px)": {
        float: "none",
        maxWidth: "60vw",
        marginLeft: "calc((100% - 60vw) / 2)",
      },
    },
  },
  form: {
    margin: 0,
    maxWidth: 360,
    "& h3": {
      color: "#04557b",
      fontSize: 44,
      marginBottom: 36,
      marginTop: 36,
    },
    "& p": {
      fontSize: 14,
      marginBottom: 30,
      textAlign: "center",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
    },
    "& .MuiFormLabel-root": {
      textTransform: "capitalize",
    },
    "& .MuiInputLabel-shrink": {
      textTransform: "uppercase",
    },
    "& > button": {
      marginTop: 36,
      padding: 12,
    },
  },
  changeForm: {
    marginTop: 24,
    textDecoration: "underline",
    cursor: "pointer",
  },
  forgotPasswordSuccess: {
    maxWidth: 360,
    textAlign: "center",
    color: "#5cc560",
    marginTop: "8vh",
    fontSize: "1.4em",
    lineHeight: "1em",
    "& > span": {
      fontSize: 40,
    },
  },
  versionNumber: {
    position: "absolute",
    bottom: "50px",
    color: theme.typography.h1.color,
    fontSize: "12px",
    textAlign: "center",
    zIndex: 1,
  },
}));

const LoginPage = () => {
  const dataObj = {
    value: "",
    error: false,
    helperText: "",
  };
  const [currentYear] = useState(new Date().getFullYear());
  const [email, setEmail] = useState({ ...dataObj });
  const [password, setPassword] = useState({ ...dataObj });
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showSuccessNotice, setShowSuccessNotice] = useState(false);
  const passwordErrorText = "Password can not be blank";

  const formRef = useRef<HTMLDivElement | any>(null);
  const navigator = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isUserAuth } = useAuth();

  const { classes } = useStyles();

  const handleForgotPasswordAbortController = new AbortController();
  const handleSubmitAbortController = new AbortController();

  useEffect(() => {
    return () => {
      handleForgotPasswordAbortController.abort();
      handleSubmitAbortController.abort();
    };
  });

  if (isUserAuth) {
    return <Navigate to={"/messaging"} />;
  }

  const handleForgotPassword = (event: React.FormEvent) => {
    event.preventDefault();

    if (!email.value || email.error) {
      setEmail({ ...email, error: true, helperText: "Invalid Email" });
      document.getElementsByName("email")[0].focus();
      return;
    }

    apiRequest({
      method: "POST",
      endpoint: "forgot-password",
      body: {
        username: email.value,
      },
      signal: handleForgotPasswordAbortController.signal,
    })
      .then(() => {
        setShowSuccessNotice(true);
      })
      .catch((error) => {
        console.warn(formatApiRequestError(error));
        enqueueSnackbar("Email not found", { variant: "error" });
      });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!email.value || email.error) {
      setEmail({ ...email, error: true, helperText: "Invalid Email" });
      document.getElementsByName("email")[0].focus();
      return;
    }
    if (!password.value || password.error) {
      setPassword({
        ...password,
        error: true,
        helperText: passwordErrorText,
      });
      document.getElementsByName("password")[0].focus();
      return;
    }

    const credentials = {
      username: email.value,
      password: password.value,
      method: CLIENT_LOGIN_METHOD,
    };

    apiRequest({
      method: "POST",
      endpoint: "sign-in",
      body: credentials,
      signal: handleSubmitAbortController.signal,
    })
      .then((response: any) => {
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", JSON.stringify(response.user));

        navigator("/messaging");
      })
      .catch((error) => {
        console.warn("Could not sign in user:", formatApiRequestError(error));
        enqueueSnackbar(
          "Could not sign in user: username or email is incorrect",
          { variant: "error" }
        );
      });
  };

  const toggleLoginForm = (value: boolean) => {
    setShowLoginForm(value);
    setEmail({ ...dataObj });
    setPassword({ ...dataObj });
  };

  const validateEmail = (value: string) => {
    validateEmailHelper(value).then((email: any) => setEmail(email));
  };

  const validatePassword = (value: string) => {
    const passwordHelperText = value === "" ? passwordErrorText : "";
    setPassword({ value, error: !value, helperText: passwordHelperText });
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid className={classes.header} item>
          <img src={logo} alt="Swept logo" />
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={6} md={6} className={classes.featureImage}>
            <img src={background} title="Swept Sign-in" alt="Swept Sign-in" />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {showLoginForm && (
              <Box
                component="form"
                className={classes.form}
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h3">Sign-in to Swept</Typography>
                  <Typography>
                    Please enter your email and password below to sign-in
                  </Typography>
                </Grid>

                <TextFieldWithValidation
                  fullWidth
                  label="Email"
                  autoComplete={"username"}
                  onBlur={(event) => validateEmail(event.target.value.trim())}
                  onChange={(event) => {
                    setEmail({ ...email, value: event.target.value });
                  }}
                  name="email"
                  type="email"
                  value={email.value}
                  margin="normal"
                  error={email.error}
                  helperText={email.error ? email.helperText : ""}
                  data-testid={"portal-login-email"}
                  variant="outlined"
                  userForm={false}
                />

                <PasswordFieldWithValidation
                  fullWidth
                  label="Password"
                  autoComplete={"current-password"}
                  onChange={(event) =>
                    validatePassword(event.target.value.trim())
                  }
                  name="password"
                  type={"password"}
                  value={password.value}
                  margin="normal"
                  error={password.error}
                  helperText={password.error ? password.helperText : ""}
                  data-testid={"portal-login-password"}
                  variant="outlined"
                  userForm={false}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  size="large"
                  fullWidth
                >
                  Sign In
                </Button>

                <Typography
                  className={classes.changeForm}
                  onClick={() => toggleLoginForm(false)}
                >
                  Forgot Password?
                </Typography>
              </Box>
            )}
            {!showLoginForm && (
              <>
                {!showSuccessNotice ? (
                  <Box
                    component="form"
                    className={classes.form}
                    ref={formRef}
                    onSubmit={handleForgotPassword}
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      <Typography variant="h3">Password Reset</Typography>
                      <Typography>
                        Please enter your email below so we can send you
                        instructions to reset the password
                      </Typography>
                    </Grid>

                    <TextFieldWithValidation
                      fullWidth
                      label="Email"
                      autoComplete={"username"}
                      onBlur={(event) =>
                        validateEmail(event.target.value.trim())
                      }
                      onChange={(event) => {
                        setEmail({ ...email, value: event.target.value });
                      }}
                      name="email"
                      type="email"
                      value={email.value}
                      margin="normal"
                      error={email.error}
                      helperText={email.error ? email.helperText : ""}
                      data-testid={"portal-forgot-email"}
                      variant="outlined"
                      userForm={false}
                    />

                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      size="large"
                      fullWidth
                    >
                      Recover Password
                    </Button>

                    <Typography
                      className={classes.changeForm}
                      onClick={() => toggleLoginForm(true)}
                    >
                      Back to Login?
                    </Typography>
                  </Box>
                ) : (
                  <Typography className={classes.forgotPasswordSuccess}>
                    <span>✓</span>Please check your email. We have sent you a
                    password reset link!
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid className={classes.versionNumber} item>
          <div>{`© ${currentYear} Swept`}</div>
          <div data-testid="current-app-version">{`${process.env.REACT_APP_SWEPT_VERSION_NAME}`}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
