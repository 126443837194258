import { createBrowserRouter } from "react-router-dom";

import InvitePage from "../auth/InvitePage";
import LoginPage from "../auth/LoginPage";
import ResetPage from "../auth/ResetPage";
import AuthRoutes from "./AuthRoutes";

const Router = createBrowserRouter([
  {
    path: "/reset",
    element: <ResetPage />,
  },
  {
    path: "/invite",
    element: <InvitePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/*",
    element: <AuthRoutes />,
  },
]);

export default Router;
