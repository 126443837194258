import { cloneElement } from "react";

import { Grid, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    borderRadius: "6px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    color: theme.palette.secondary.contrastText,
    fontFamily: "inherit",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    height: "180px",
    justifyContent: "flex-start",
    width: "100%",
  },
  icon: {
    fontSize: "70px",
    lineHeight: "70px",
    width: "70px",
  },
  title: {
    paddingRight: theme.spacing(2),
  },
}));

interface ComponentPropsType {
  description: string;
  gradient: string;
  icon: any;
  rating: number;
  title: string;
  tooltip: string;
}

const RatingCard = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { description, gradient, icon, rating, title, tooltip } = props;

  const iconWithProps = cloneElement(icon, {
    fontSize: "inherit",
    color: "inherit",
  });

  return (
    <Grid
      className={classes.card}
      container
      alignContent="center"
      justifyContent="flex-start"
      style={{ background: gradient }}
    >
      <Grid
        className={classes.icon}
        container
        justifyContent="center"
        direction="column"
        item
        xs={5}
      >
        <Grid container justifyContent="center" item>
          {iconWithProps}
        </Grid>
      </Grid>
      <Tooltip placement="bottom-start" title={tooltip}>
        <Grid
          container
          alignContent="flex-start"
          justifyContent="center"
          direction="column"
          item
          xs={7}
        >
          <Grid className={classes.bold} item>
            <span>
              {rating}
              {description}
            </span>
          </Grid>
          <Grid className={classes.title} item>
            {title}
          </Grid>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default RatingCard;
