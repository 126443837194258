import { useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { DRAWER_BREAKPOINT } from "../../constants";
import { AppbarPropsType } from "../../types";
import AppBarFull from "./AppBarFull";
import AppBarSimple from "./AppBarSimple";

const AppBarResponsive = (
  props: Pick<
    AppbarPropsType,
    | "badgeCounts"
    | "isBrowserDrawerExpanded"
    | "onLogoutUser"
    | "onToggleBrowserDrawer"
    | "onToggleMobileDrawer"
  >
) => {
  const theme = useTheme();
  const [isMouseInAvatar, setIsMouseInAvatar] = useState(false);
  const [isMouseInDropdown, setIsMouseInDropdown] = useState(false);

  const handleClickAvatar = () => {
    setIsMouseInAvatar(true);
  };

  const handleMouseLeftAvatar = () => {
    setIsMouseInAvatar(false);
  };

  const handleMouseEnteredDropdown = () => {
    setIsMouseInDropdown(true);
  };

  const handleMouseLeftDropdown = () => {
    setIsMouseInDropdown(false);
  };

  const Component = useMediaQuery(theme.breakpoints.up(DRAWER_BREAKPOINT))
    ? AppBarFull
    : AppBarSimple;

  return (
    <Component
      onClickAvatar={handleClickAvatar}
      onMouseEnteredDropdown={handleMouseEnteredDropdown}
      onMouseLeftAvatar={handleMouseLeftAvatar}
      onMouseLeftDropdown={handleMouseLeftDropdown}
      showSettingsDropdown={isMouseInAvatar || isMouseInDropdown}
      {...props}
    />
  );
};

export default AppBarResponsive;
