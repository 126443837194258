import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import classnames from "classnames";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  red: {
    color: "#FF6666",
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(28),
  },
}));

interface ComponentPropsType {
  error?: boolean;
  id?: string;
  sticky?: boolean;
  children?: React.ReactNode;
  onDismiss?: () => void;
}

const MuiDialogTitle = (props: ComponentPropsType) => {
  const { children, id, sticky = false, onDismiss } = props;
  const { classes } = useStyles();
  return (
    <DialogTitle id={id} className={classnames(sticky)} variant="h6">
      <Typography className={classnames(classes.red)} variant="inherit">
        {children}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={onDismiss}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default MuiDialogTitle;
