import { SendFileAPIResponse, logChatPromiseExecution } from "stream-chat";
import {
  MessageList,
  MessageInput,
  Thread,
  Window,
  useChannelActionContext,
} from "stream-chat-react";

import { apiRequest } from "../../../../helpers";
import { MAX_FILE_SIZE } from "../../constant";
import { CustomMessageSimple } from "../Message/CustomMessageSimple";
import { TeamChannelHeader } from "../TeamChannelHeader/TeamChannelHeader";

export const ChannelInner = () => {
  const { sendMessage } = useChannelActionContext();
  const uploadImageAbortController = new AbortController();

  const overrideSubmitHandler = (message: any) => {
    let emptyUrl = false;

    message.attachments.forEach((attachment: any) => {
      if (
        ("asset_url" in attachment && attachment.asset_url === "") ||
        ("image_url" in attachment && attachment.image_url === "")
      ) {
        emptyUrl = true;
      }
    });

    if (sendMessage && !emptyUrl) {
      const updatedMessage = {
        attachments: message.attachments,
        mentioned_users: message.mentioned_users,
        parent_id: message.parent ? message.parent.id : null,
        parent: message.parent,
        text: message.text,
      };
      const sendMessagePromise = sendMessage(updatedMessage);
      logChatPromiseExecution(sendMessagePromise, "send message");
    }
  };

  const doS3FileUploadRequest = async (file: any) => {
    const fileToUpload = new FormData();
    let uploadedFileUrl = "";
    fileToUpload.append("file", file);
    if (file.size <= MAX_FILE_SIZE) {
      const uploadUrl = await apiRequest({
        method: "POST",
        endpoint: "presigned-upload",
        body: { file_name: file.name },
        signal: uploadImageAbortController.signal,
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });

      const { url, fields }: any = uploadUrl;

      const bodyData = new FormData();
      Object.keys(fields).forEach((key) => {
        bodyData.append(key, fields[key]);
      });
      bodyData.append("file", file);

      await fetch(url, {
        method: "POST",
        body: bodyData,
      })
        .then(() => {
          uploadedFileUrl = `https://${process.env.REACT_APP_AWS_S3_BUCKET_CLOUDFRONT_CNAME}/${fields.key}`;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return new Promise<SendFileAPIResponse>((resolve) =>
      resolve({
        duration: "",
        file: uploadedFileUrl,
      })
    );
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Window>
        <TeamChannelHeader />
        <MessageList Message={CustomMessageSimple} />
        <MessageInput
          grow
          overrideSubmitHandler={overrideSubmitHandler}
          doImageUploadRequest={doS3FileUploadRequest}
          doFileUploadRequest={doS3FileUploadRequest}
        />
      </Window>
      <Thread />
    </div>
  );
};
