import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { FileUploadButton } from "react-file-utils";
import {
  AttachmentPreviewList,
  ChatAutoComplete,
  EmojiPicker,
  SendButton,
  useChannelStateContext,
  useComponentContext,
  useMessageInputContext,
} from "stream-chat-react";
import "./TeamMessageInput.css";

import { FileIcon } from "../../assests/FileIcon";
import { useMessageInputCompositionControls } from "./hooks/useMessageInputCompositionControls";
import { MessageInputControlButton } from "./MessageInputControls";

export const TeamMessageInput = () => {
  const { TypingIndicator } = useComponentContext();

  const { acceptedFiles, multipleUploads, maxNumberOfFiles } =
    useChannelStateContext();
  const {
    handleSubmit,
    numberOfUploads,
    text,
    uploadNewFiles,
    maxFilesLeft,
    isUploadEnabled,
    openEmojiPicker,
    closeEmojiPicker,
    emojiPickerIsOpen,
  } = useMessageInputContext();
  const { onChange } = useMessageInputCompositionControls();

  const { getRootProps, isDragActive, isDragReject } = useDropzone({
    accept: acceptedFiles,
    disabled: !isUploadEnabled || maxFilesLeft === 0,
    multiple: multipleUploads,
    noClick: true,
    onDrop: uploadNewFiles,
  });

  return (
    <div
      {...getRootProps({
        className: classNames(`team-message-input__wrapper`),
      })}
    >
      {isDragActive && (
        <div
          className={classNames("str-chat__dropzone-container", {
            "str-chat__dropzone-container--not-accepted": isDragReject,
          })}
        >
          {!isDragReject && <p>Drag your files here</p>}
          {isDragReject && <p>Only images are accepted</p>}
        </div>
      )}
      <div className="team-message-input__input">
        <div className="team-message-input__top">
          {!!numberOfUploads && <AttachmentPreviewList />}
          <div className="team-message-input__form">
            <ChatAutoComplete
              onChange={onChange}
              placeholder="Type your message"
            />

            <SendButton
              disabled={!numberOfUploads && !text.length}
              sendMessage={handleSubmit}
            />
          </div>
        </div>
        <div className="team-message-input__bottom">
          <MessageInputControlButton
            type="emoji"
            onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
          />
          <FileUploadButton
            accepts={acceptedFiles}
            handleFiles={uploadNewFiles}
            multiple={multipleUploads}
            disabled={
              maxNumberOfFiles !== undefined &&
              numberOfUploads >= maxNumberOfFiles
            }
          >
            <FileIcon />
          </FileUploadButton>
        </div>
      </div>
      {TypingIndicator && <TypingIndicator />}
      <EmojiPicker />
    </div>
  );
};
