import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { StreamChat } from "stream-chat";

import {
  formatApiRequestError,
  UserContext,
  handleSessionCheck,
} from "../../../helpers";
import { ErrorType } from "../../../types";
import { useTitle } from "../../hooks/useTitle";
import {
  STREAM_CONNECTION_TIMEOUT,
  STREAM_SERVICE_TIMEOUT,
  TIMER_DEBOUNCE_TIME,
} from "../../messaging/constant";
import ModalServerError from "../../modals/ModalServerError";
import PageHeader from "../../pageheader/PageHeader";
import MessageInbox from "./MessageInbox/MessageInbox";

const MessageInboxView = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showServerErrorModal, setShowServerErrorModal] = useState(false);
  const disconnectionTimeout = useRef<number | undefined>(undefined);
  const [chatClient, setChatClient] = useState(null);

  const { user } = useContext(UserContext);
  const userId = user.id.toString();
  const chatToken = user.chatToken;
  const client = new StreamChat(process.env.REACT_APP_STREAM_KEY || "", {
    timeout: STREAM_CONNECTION_TIMEOUT,
  });

  useEffect(() => {
    handleConnection(client);
    document.body.addEventListener("click", resetDisconnectionTimer);
    document.body.addEventListener("keydown", resetDisconnectionTimer);
    document.body.addEventListener("mousemove", resetDisconnectionTimer);
    document.body.addEventListener("scroll", resetDisconnectionTimer);
    document.body.addEventListener("touchstart", resetDisconnectionTimer);
    return () => {
      client.disconnectUser();
      setChatClient(null);
      document.body.removeEventListener("click", resetDisconnectionTimer);
      document.body.removeEventListener("keydown", resetDisconnectionTimer);
      document.body.removeEventListener("mousemove", resetDisconnectionTimer);
      document.body.removeEventListener("scroll", resetDisconnectionTimer);
      document.body.removeEventListener("touchstart", resetDisconnectionTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnection = (client: any) => {
    handleSessionCheck();
    setShowInfoModal(false);
    connectChat(client)
      .then((response) => {
        if (response && response.me) {
          setChatClient(client);
          clearTimeout(disconnectionTimeout.current);
          disconnectionTimer(client);
        }
      })
      .catch((error) => {
        handleServerError(error, "Could not connect to chat service:");
      });
  };

  const connectChat = async (client: any) => {
    return await client.connectUser({ id: userId }, chatToken);
  };

  const handleReconnect = () => {
    handleConnection(client);
  };

  const disconnectionTimer = (client: any) => {
    disconnectionTimeout.current = window.setTimeout(async () => {
      clearTimeout(disconnectionTimeout.current);
      if (chatClient) await client.disconnectUser();
      setShowInfoModal(true);
    }, STREAM_SERVICE_TIMEOUT);
  };

  let resetDisconnectionTimer = debounce(() => {
    clearTimeout(disconnectionTimeout.current);
    disconnectionTimer(client);
  }, TIMER_DEBOUNCE_TIME);

  const handleServerError = (error: ErrorType, description: string) => {
    console.warn(description, formatApiRequestError(error));
    setShowServerErrorModal(true);
  };

  useTitle("Messaging");

  return (
    <>
      <PageHeader title="Inbox" divider />
      {chatClient && !showInfoModal && !showServerErrorModal && (
        <MessageInbox client={chatClient} />
      )}
      <Dialog
        open={showInfoModal}
        onClose={handleReconnect}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Chat Disconnected"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The chat has been disconnected due to inactivity. Click OK to
            reconnect.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReconnect} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <ModalServerError
        onDismiss={() => setShowServerErrorModal(false)}
        open={showServerErrorModal}
      />
    </>
  );
};

export default MessageInboxView;
