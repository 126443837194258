import React, { useContext } from "react";

import { Paper, Table } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import {
  APPBAR_HEIGHT,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  DRAWER_BREAKPOINT,
} from "../../constants";
import { DrawerContext } from "../../helpers";
import { drawerCollapse, drawerExpansion } from "../../theme";
import { columnObj, TableToolbarPropType } from "../../types";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";

const useStyles = makeStyles()((theme) => ({
  hidden: {
    visibility: "hidden",
  },
  visible: {},
  stickyContainer: {
    backgroundColor: theme.palette.background.paper,
    width: "100vw",
    position: "fixed",
    top: APPBAR_HEIGHT,
    left: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  stickyContainerDrawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      left: DRAWER_WIDTH_COLLAPSED,
      transition: drawerCollapse.transitionLeft,
      width: `calc(100vw - ${DRAWER_WIDTH_COLLAPSED})`,
    },
  },
  stickyContainerDrawerExpanded: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      left: DRAWER_WIDTH_EXPANDED,
      transition: drawerExpansion.transitionLeft,
      width: `calc(100% - ${DRAWER_WIDTH_EXPANDED})`,
    },
  },
  stickyContent: {
    width: "100%",
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      maxWidth: `calc(1200px - ${theme.spacing(8)})`,
    },
  },
  stickyContentDrawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      width: `calc(100vw - ${DRAWER_WIDTH_COLLAPSED} - ${theme.spacing(
        10
      )} - (100vw - ${document.body.clientWidth}))`,
    },
  },
  stickyContentDrawerExpanded: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      width: `calc(100vw - ${DRAWER_WIDTH_EXPANDED} - ${theme.spacing(
        10
      )} - (100vw - ${document.body.clientWidth}))`,
    },
  },
  stickyContentPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  stickyContentPaddingDrawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      paddingRight: theme.spacing(6),
    },
  },
  stickyDivider: {
    borderBottom: theme.divider.default,
  },
  stickyTotalsRowDivider: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)", // MuiTableCell-root defaults
    position: "fixed",
    width: "100%",
  },
}));

interface ShowOnScrollPropsType {
  children: React.ReactNode;
  threshold: number;
}

const ShowOnScroll = (props: ShowOnScrollPropsType) => {
  const { classes } = useStyles();
  const { children, threshold } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold,
  });

  return (
    <div className={trigger ? classes.visible : classes.hidden}>{children}</div>
  );
};

interface TableStickyHeaderPropsType {
  columnsLarge: columnObj[];
  columnsSmall: columnObj[];
  showHeaderRow: boolean;
  tableClassName: any;
  threshold: number;
  toolbarProps: TableToolbarPropType;
}

const TableStickyHeader = (props: TableStickyHeaderPropsType) => {
  const { classes } = useStyles();
  const {
    columnsLarge,
    columnsSmall,
    showHeaderRow,
    tableClassName,
    threshold,
    toolbarProps,
  } = props;
  const isBrowserDrawerExpanded = useContext(DrawerContext);

  const tableTotalsRow = document.getElementById("table-totals-row-sticky");
  const tableTotalsRowBottom = tableTotalsRow
    ? tableTotalsRow.getBoundingClientRect().bottom
    : 0;

  return (
    <ShowOnScroll threshold={threshold}>
      <Paper
        square
        className={classNames(
          classes.stickyContainer,
          isBrowserDrawerExpanded
            ? classes.stickyContainerDrawerExpanded
            : classes.stickyContainerDrawerCollapsed
        )}
      >
        <div
          className={classNames(classes.stickyContentPadding, {
            [classes.stickyContentPaddingDrawerCollapsed]:
              !isBrowserDrawerExpanded,
          })}
        >
          <div
            className={classNames(
              classes.stickyContent,
              isBrowserDrawerExpanded
                ? classes.stickyContentDrawerExpanded
                : classes.stickyContentDrawerCollapsed
            )}
          >
            <TableToolbar sticky={true} {...toolbarProps} />
          </div>
        </div>
        <div className={classes.stickyDivider} />
        {tableTotalsRow && (
          <div
            className={classes.stickyTotalsRowDivider}
            style={{ top: tableTotalsRowBottom }}
          />
        )}
        <div
          className={classNames(classes.stickyContentPadding, {
            [classes.stickyContentPaddingDrawerCollapsed]:
              !isBrowserDrawerExpanded,
          })}
        >
          <div className={classes.stickyContent}>
            {showHeaderRow && (
              <Table className={tableClassName}>
                <TableHeader
                  columnsLarge={columnsLarge}
                  columnsSmall={columnsSmall}
                  sticky={true}
                />
              </Table>
            )}
          </div>
        </div>
      </Paper>
    </ShowOnScroll>
  );
};

export default TableStickyHeader;
