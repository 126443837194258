import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { AppbarPropsType } from "../../types";

const useStyles = makeStyles()((theme) => ({
  dropdown: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    boxShadow: [
      "0 2px 2px 0 rgba(0,0,0,0.14)",
      "0 1px 5px 0 rgba(0,0,0,0.12)",
      "0 3px 1px -2px rgba(0,0,0,0.2)",
    ].join(","),
    position: "absolute",
    top: "60px",
    right: "0",
    width: "150px",
  },
  nested: {
    paddingLeft: "10px",
    textAlign: "center",
  },
  listIcon: {
    minWidth: 24,
  },
}));

const AppBarDropdown = (
  props: Pick<
    AppbarPropsType,
    | "onLogoutUser"
    | "onMouseEnteredDropdown"
    | "onMouseLeftDropdown"
    | "showSettingsDropdown"
  >
) => {
  const {
    onLogoutUser,
    onMouseLeftDropdown,
    onMouseEnteredDropdown,
    showSettingsDropdown,
  } = props;
  const navigate = useNavigate();
  const { classes } = useStyles();

  const settingItems = [
    {
      id: "app-menu-sign-out",
      label: "Sign Out",
      to: "/",
      icon: <PowerSettingsNewIcon />,
    },
  ];

  const settingsDropdownJSX = settingItems.map((item) => {
    let action: any = () => {};
    if (item.id === "app-menu-support-center") {
      action = () => {
        window.location.href = item.to;
      };
    } else if (item.id === "app-menu-sign-out") {
      action = onLogoutUser;
    } else {
      action = () => {
        navigate(item.to);
      };
    }

    return (
      <ListItemButton
        id={item.id}
        className={classes.nested}
        key={item.label}
        onClick={action}
      >
        <ListItemIcon className={classes.listIcon}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItemButton>
    );
  });

  return (
    <Collapse
      in={showSettingsDropdown}
      timeout="auto"
      className={classes.dropdown}
      onMouseLeave={onMouseLeftDropdown}
      onMouseEnter={onMouseEnteredDropdown}
    >
      <List component="div" disablePadding>
        {settingsDropdownJSX}
      </List>
    </Collapse>
  );
};

export default AppBarDropdown;
