import React from "react";

import { Divider, Grid, Hidden, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import OverflowTooltip from "../shared/OverflowTooltip";
import SweptCrumbs from "./SweptCrumbs";

const useStyles = makeStyles()((theme) => ({
  breadcrumbs: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  childContainer: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headContainer: {
    marginBottom: theme.spacing(3),
    maxWidth: "1200px",
  },
  title: {
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  titleContainer: {
    flexGrow: 2,
    minWidth: "1px",
  },
}));

interface ComponentPropsType {
  title: string;
  crumbs?: {
    label: string;
    to?: string;
  }[];
  divider: boolean;
  children?: React.ReactNode;
}

const PageHeader = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { crumbs, divider, title } = props;
  const children = React.Children.toArray(props.children);

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.headContainer}
      >
        {crumbs && (
          <Grid item xs={12} className={classes.breadcrumbs}>
            <SweptCrumbs crumbs={crumbs} />
          </Grid>
        )}
        <Grid className={classes.titleContainer} item xs={12} sm>
          <Typography className={classes.title} variant={"h1"}>
            <OverflowTooltip title={title}>{title}</OverflowTooltip>
          </Typography>
        </Grid>
        {props.children && (
          <>
            <Hidden xsDown>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                xs
              >
                {children &&
                  children.map((child, index) => (
                    <Grid item key={index}>
                      {child}
                    </Grid>
                  ))}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                className={classes.childContainer}
              >
                {children &&
                  children.map((child, index) => (
                    <Grid item key={index}>
                      {child}
                    </Grid>
                  ))}
              </Grid>
            </Hidden>
          </>
        )}
      </Grid>
      {divider && <Divider className={classes.divider} />}
    </>
  );
};

export default PageHeader;
