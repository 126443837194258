import React, { useContext, useState } from "react";

import classNames from "classnames";
import {
  useChatContext,
  MessageContextValue,
  useMessageContext,
  useComponentContext,
  EditMessageForm as DefaultEditMessageForm,
  MessageInput,
  MML,
  Modal,
  ReactionsList as DefaultReactionList,
  ReactionSelector as DefaultReactionSelector,
  areMessageUIPropsEqual,
  messageHasAttachments,
  messageHasReactions,
  MessageUIComponentProps,
  MessageTimestamp as DefaultMessageTimestamp,
  MessageText,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageDeleted as DefaultMessageDeleted,
  MessageErrorIcon,
} from "stream-chat-react";
import "./CustomMessageSimple.css";

import {
  UserContext,
  translateMessage,
  resolveBrowserLocale,
  apiRequest,
} from "../../../../helpers";
import type { DefaultStreamChatGenerics } from "../../../../types";
import { CUSTOM_MESSAGE_TYPE } from "../../constant";
import MessagingAvatar from "../MessagingAvatar/MessagingAvatar";
import { MessageOptions as DefaultMessageOptions } from "./MessageOptions";
import { MessageStatus as DefaultMessageStatus } from "./MessageStatus";

const customReactions = [
  {
    id: "heart",
    name: "Heart",
  },
  {
    id: "+1",
    name: "Thumbs Up",
  },
  {
    id: "-1",
    name: "Thumbs Down",
  },
  {
    id: "laughing",
    name: "Grinning Squinting Face",
  },
  {
    id: "interrobang",
    name: "Exclamation Question Mark",
  },
];

type MessageSimpleWithContextProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = MessageContextValue<StreamChatGenerics>;

const MessageSimpleWithContext = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    highlighted,
    isMyMessage,
    isReactionEnabled,
    handleReaction,
    message,
    reactionSelectorRef,
    renderText,
    showDetailedReactions,
    threadList,
  } = props;

  const {
    Attachment,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageOptions = DefaultMessageOptions,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageStatus = DefaultMessageStatus,
    MessageTimestamp = DefaultMessageTimestamp,
    ReactionSelector = DefaultReactionSelector,
    ReactionsList = DefaultReactionList,
  } = useComponentContext<StreamChatGenerics>("MessageSimple");
  const { themeVersion } = useChatContext("MessageSimple");

  const { user } = useContext(UserContext);
  const originalText = message.text;
  const messageCopy = JSON.parse(JSON.stringify(message));
  let { translatedMessage, showTranslateIcon, isTranslated } = translateMessage(
    messageCopy,
    user.preferredLanguage,
    user.isTranslationEnabled
  );
  const initialTranslated = isTranslated ? translatedMessage.text : null;
  const initialText = initialTranslated ? initialTranslated : originalText;
  const [messageText, setMessageText] = useState(initialText);
  const [translatedText, setTranslatedText] = useState(initialTranslated);
  let updatedMessage = { ...messageCopy, text: messageText };

  const createTranslation = (message: any) => {
    const language = resolveBrowserLocale(user.preferredLanguage);
    let translatedText = apiRequest({
      method: "POST",
      endpoint: "translate",
      body: {
        id: message.id,
        type: "message",
        language: language,
        userId: user.id,
      },
    })
      .then((response: any) => {
        translatedText = response.translatedText;
        setTranslatedText(translatedText);
        setMessageText(translatedText);
      })
      .catch((error: any) => {
        if (error.status >= 400) {
          console.warn("Could not create new translation data for message");
        }
      });
  };

  const handleTranslate = () => {
    if (updatedMessage.text === originalText && !translatedText) {
      createTranslation(updatedMessage);
    } else if (updatedMessage.text === originalText && translatedText) {
      setMessageText(translatedText);
    } else {
      setMessageText(originalText);
    }
  };

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />;
  }

  const showMetadata = !groupedByUser || endOfGroup;
  const showReplyCountButton = !threadList && !!message.reply_count;
  const allowRetry =
    message.status === "failed" && message.errorStatusCode !== 403;

  const rootClassName = classNames(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage()
      ? "str-chat__message--me str-chat__message-simple--me"
      : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "pinned-message": message.pinned,
      "str-chat__message--has-attachment": hasAttachment,
      "str-chat__message--highlighted": highlighted,
      "str-chat__message--with-reactions str-chat__message-with-thread-link":
        hasReactions && isReactionEnabled,
      "str-chat__message-send-can-be-retried":
        message?.status === "failed" && message?.errorStatusCode !== 403,
      "str-chat__virtual-message__wrapper--end": endOfGroup,
      "str-chat__virtual-message__wrapper--first": firstOfGroup,
      "str-chat__virtual-message__wrapper--group": groupedByUser,
    }
  );

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div className={rootClassName} key={message.id}>
          {themeVersion === "1" && <MessageStatus />}
          {message.user && !isMyMessage() && (
            <MessagingAvatar
              userName={message.user.name || ""}
              userType={message.user.swept_type}
              size={"32"}
            />
          )}
          <div
            className={classNames("str-chat__message-inner", {
              "str-chat__simple-message--error-failed": allowRetry,
            })}
            data-testid="message-inner"
            onClick={allowRetry ? () => handleRetry(message) : undefined}
            onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
          >
            <MessageOptions
              handleTranslate={handleTranslate}
              showTranslateIcon={showTranslateIcon}
            />
            <div
              className="str-chat__message-reactions-host"
              data-testid="message-team-actions"
            >
              {hasReactions && isReactionEnabled && (
                <ReactionsList
                  reaction_counts={message.reaction_counts || undefined}
                  reactions={message.latest_reactions}
                  reactionOptions={customReactions}
                />
              )}
              {showDetailedReactions && isReactionEnabled && (
                <ReactionSelector
                  ref={reactionSelectorRef}
                  detailedView={true}
                  handleReaction={handleReaction}
                  latest_reactions={message.latest_reactions}
                  reaction_counts={message.reaction_counts || undefined}
                  reactionOptions={customReactions}
                />
              )}
            </div>
            <div className="str-chat__message-bubble">
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment
                  actionHandler={handleAction}
                  attachments={message.attachments}
                />
              ) : null}
              <MessageText message={updatedMessage} renderText={renderText} />
              {message.mml && (
                <MML
                  actionHandler={handleAction}
                  align={isMyMessage() ? "right" : "left"}
                  source={message.mml}
                />
              )}
              {themeVersion === "2" && <MessageErrorIcon />}
            </div>
            {showReplyCountButton && themeVersion === "1" && (
              <MessageRepliesCountButton
                onClick={handleOpenThread}
                reply_count={message.reply_count}
              />
            )}
            {showMetadata && themeVersion === "1" && (
              <div className="str-chat__message-data str-chat__message-simple-data">
                {!isMyMessage() && message.user ? (
                  <span className="str-chat__message-simple-name">
                    {message.user.name || message.user.id}
                  </span>
                ) : null}
                <MessageTimestamp
                  calendar
                  customClass="str-chat__message-simple-timestamp"
                />
              </div>
            )}
          </div>
          {showReplyCountButton && themeVersion === "2" && (
            <MessageRepliesCountButton
              onClick={handleOpenThread}
              reply_count={message.reply_count}
            />
          )}
          {showMetadata && themeVersion === "2" && (
            <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
              <MessageStatus />
              {!isMyMessage() && !!message.user && (
                <span className="str-chat__message-simple-name">
                  {message.user.name || message.user.id}
                </span>
              )}
              <MessageTimestamp
                calendar
                customClass="str-chat__message-simple-timestamp"
              />
            </div>
          )}
        </div>
      }
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const CustomMessageSimple = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageUIComponentProps<StreamChatGenerics>
) => {
  const messageContext = useMessageContext<StreamChatGenerics>("MessageSimple");

  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
