import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.text.grey,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    padding: 0,
  },
  item: {
    color: theme.palette.text.grey,
    overflow: "hidden",
    minHeight: "48px",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.text.hover,
    },
  },
  selected: {
    color: theme.typography.h1.color,
  },
}));

interface ComponentPropsType {
  id: string;
  icon: any;
  isDrawerOpen: boolean;
  onClick: Function;
  text: string;
  to: string;
  isSelected: boolean;
}

const DrawerItem = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { icon, id, isDrawerOpen, onClick, text, to, isSelected } = props;
  const navigate = useNavigate();

  return (
    <ListItem
      id={`drawer-item-${id}`}
      data-testid={`drawer-item-${id}`}
      className={classNames(classes.item, {
        [classes.selected]: isSelected,
      })}
      onClick={() => {
        onClick();
        navigate(to);
      }}
    >
      <ListItemIcon
        className={classNames(classes.icon, {
          [classes.selected]: isSelected,
        })}
      >
        {icon}
      </ListItemIcon>
      {isDrawerOpen && <ListItemText>{text}</ListItemText>}
    </ListItem>
  );
};

export default DrawerItem;
