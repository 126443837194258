import { TableToolBarParams } from "types";

const reportQueryParams = (options: TableToolBarParams) => {
  const jsKeyMap = {
    dateEnd: "ends_on",
    dateStart: "starts_on",
    groupBy: "group_by",
    groupPerPage: "group_per_page",
    locationId: "location_id",
    baseline: "baseline",
    searchText: "search",
    sortBy: "sorts",
    timezone: "timezone",
    timeFrameId: "time_frame_id",
  };

  const apiParams: any = {};

  Object.entries(jsKeyMap).forEach(([jsKey, apiKey]) => {
    if (
      options[jsKey] &&
      !(Array.isArray(options[jsKey]) && options[jsKey].length === 0)
    ) {
      apiParams[apiKey] = options[jsKey];
    }
  });

  return apiParams;
};

export { reportQueryParams };
