import { Link } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  link: {
    color: "#039BE5",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      color: "#03BEFF",
    },
  },
}));

interface ComponentPropsType {
  children: any;
  to: string;
  className?: any;
}

const SweptLink = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { children, to, className } = props;
  const navigate = useNavigate();

  return (
    <Link
      className={classNames(classes.link, className)}
      href={to}
      onClick={(event) => {
        event.preventDefault();
        navigate(to);
      }}
    >
      {children}
    </Link>
  );
};

export default SweptLink;
