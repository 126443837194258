import { CheckCircle, Error } from "@mui/icons-material";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  error: {
    color: theme.palette.error.main,
  },
  hidden: {
    display: "none",
  },
  inputLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: theme.spacing(1),
  },
  inputError: {
    "&:before": {
      borderBottomColor: theme.palette.error.main,
    },
  },
  inputFocused: {},
  inputRoot: {
    "& input::placeholder": {
      fontSize: "0.85em",
    },
  },
  inputSuccess: {
    "& .MuiInputBase-root.Mui-focused > *": {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
    "& .MuiFormHelperText-filled": {
      color: "rgba(0,0,0,0.6)",
    },
    "& $inputLabel": {
      animation: "1.3s $flashSuccessColor linear 1",
    },
    "& $inputRoot $notchedOutline": {
      animation: "1.3s $flashSuccessBorderColor linear 1",
    },
    "& $inputRoot $success": {
      animation: "1.3s $flashSuccessIcon linear 1",
    },
    "& $inputFocused": {
      "&$inputLabel": {
        color: theme.palette.success.main,
      },
      "& $notchedOutline": {
        borderColor: theme.palette.success.main,
      },
      "& $success": {
        color: theme.palette.success.main,
      },
    },
  },
  labelColor: {
    backgroundColor: theme.palette.background.default,
    "&.Mui-focused": {
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
  },
  notchedOutline: {},
  success: {
    color: theme.palette.text.blurred,
  },
  upperText: {
    textTransform: "uppercase",
  },
  "@keyframes flashSuccessColor": {
    "0%": { color: theme.palette.success.main },
    "80%": { color: theme.palette.success.main },
    "100%": { color: theme.palette.text.secondary },
  },
  "@keyframes flashSuccessIcon": {
    "0%": { color: theme.palette.success.main },
    "80%": { color: theme.palette.success.main },
    "100%": { color: theme.palette.text.blurred },
  },
  "@keyframes flashSuccessBorderColor": {
    "0%": { borderColor: theme.palette.success.main },
    "80%": { borderColor: theme.palette.success.main },
    "100%": { borderColor: theme.palette.text.blurred },
  },
}));

interface ComponentPropsType {
  className?: string;
  inputId?: string;
  inviteForm?: boolean;
  showCountryList?: boolean;
  showPasswordButton?: JSX.Element;
  value?: string;
  userForm?: boolean;
  error?: boolean | string;
  type?: string;
  variant?: string;
  shrinkLabel?: boolean;
}

const TextFieldWithValidation = (
  props: ComponentPropsType & TextFieldProps
) => {
  const {
    className,
    inputId,
    showCountryList,
    showPasswordButton,
    value,
    userForm,
    error,
    shrinkLabel,
    inviteForm = false,
    variant = "standard",
    ...rest
  } = props;

  const { classes } = useStyles();

  return (
    <TextField
      {...rest}
      value={value}
      margin="normal"
      variant={variant}
      className={classNames(
        className,
        !error && value && inviteForm ? classes.inputSuccess : ""
      )}
      InputLabelProps={{
        classes: {
          root: classNames({
            [classes.labelColor]: true,
            [classes.upperText]: userForm || showPasswordButton,
            [classes.inputLabel]: !(userForm || showPasswordButton),
          }),
        },
        error: error,
        shrink: shrinkLabel,
      }}
      InputProps={{
        id: inputId,
        classes: {
          root: classes.inputRoot,
          focused: classes.inputFocused,
          error: classes.inputError,
        },
        error: error,
        startAdornment: showCountryList && <>{showCountryList}</>,
        endAdornment: !userForm && (
          <>
            {showPasswordButton}
            {inviteForm && (
              <InputAdornment position="end">
                {error ? (
                  <Error className={classes.error} />
                ) : (
                  <CheckCircle
                    className={value ? classes.success : classes.hidden}
                  />
                )}
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default TextFieldWithValidation;
