import { red, grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const SWEPT_BLUE = "#1d557b";

declare module "@mui/material/styles" {
  interface Theme {
    divider: {
      default: string;
      light: string;
    };
    buttons: {
      red: {};
    };
    border: {
      onFocus?: {};
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    divider?: {
      default?: string;
      light?: string;
    };
    buttons?: {
      red?: {};
    };
    border?: {
      onFocus?: {};
    };
  }
  interface TypeBackground {
    error?: string;
    hover?: string;
    main?: string;
    grey?: string;
  }
  interface TypeText {
    grey?: string;
    cardHeader?: string;
    hover?: string;
  }
}

const sweptTheme = createTheme({
  palette: {
    primary: { main: SWEPT_BLUE, contrastText: "#fff" },
    secondary: { main: "#009eef", contrastText: "#fff" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    error: {
      light: "#FF8484",
      main: red[400],
      dark: red[600],
      contrastText: "#FFF",
    },
    success: {
      light: "#33D684",
      main: "#00CC66",
      dark: "#008E47",
      contrastText: "#FFF",
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.7)",
      grey: grey[700],
      hover: grey[400],
      cardHeader: "#AEAEAE",
    },
    background: {
      main: SWEPT_BLUE,
      error: red[400],
      hover: "#E0F8FF",
      grey: grey[200],
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Roboto", "Calibri", "Arial"].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: ["OpenSans-Regular", "Open Sans", "sans-serif"].join(","),
      fontSize: 28,
      fontWeight: 700,
      color: "#04557B",
      lineHeight: 1.2,
    },
  },
  divider: {
    default: "1px solid rgba(0, 0, 0, 0.12)",
    light: "1px solid rgba(255, 255, 255, 0.1)",
  },
  buttons: {
    red: {
      background: "#FF6666",
      color: "#FFF",
      "&:hover": {
        background: "#C6323C",
      },
      "&:disabled": {
        color: "#FFF",
        opacity: 0.5,
      },
    },
  },
  border: {
    onFocus: {
      // the transparent border is there to make space for the focused border
      borderBottom: "2px solid transparent",
      "&:focus-within": {
        borderBottom: "2px solid #009EEF",
      },
    },
  },
  components: {
    MuiButton: {
      // override the styles of all instances of this component
      styleOverrides: {
        root: {},
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingLeft: "2.5rem",
          maxWidth: "1200px",
          "@media (max-width:600px)": {
            paddingLeft: "16px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& label": {
            textTransform: "uppercase",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: { root: { minWidth: "48px" } },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          head: {
            fontSize: 14,
            fontWeight: 700,
            color: "rgb(51, 51, 51, 0.7)",
            fontFamily: ["OpenSans-Bold", "Open Sans Bold", "Open Sans"].join(
              ","
            ),
          },
          body: {
            fontSize: 14,
            fontWeight: 400,
            color: "rgb(51, 51, 51, 0.7)",
            fontFamily: ["OpenSans-Regular", "Open Sans"].join(","),
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&$hover:hover": {
            backgroundColor: "#E0F8FF",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "@media (min-width:600px)": {
            paddingLeft: 0,
          },
        },
      },
    },
  },
});

const drawerCollapse = {
  transitionLeft: sweptTheme.transitions.create("left", {
    easing: sweptTheme.transitions.easing.sharp,
    duration: sweptTheme.transitions.duration.leavingScreen,
  }),
  transitionMargin: sweptTheme.transitions.create("margin-left", {
    easing: sweptTheme.transitions.easing.sharp,
    duration: sweptTheme.transitions.duration.leavingScreen,
  }),
  transitionWidth: sweptTheme.transitions.create("width", {
    easing: sweptTheme.transitions.easing.sharp,
    duration: sweptTheme.transitions.duration.leavingScreen,
  }),
};

const drawerExpansion = {
  transitionLeft: sweptTheme.transitions.create("left", {
    easing: sweptTheme.transitions.easing.sharp,
    duration: sweptTheme.transitions.duration.enteringScreen,
  }),
  transitionMargin: sweptTheme.transitions.create("margin-left", {
    easing: sweptTheme.transitions.easing.sharp,
    duration: sweptTheme.transitions.duration.enteringScreen,
  }),
  transitionWidth: sweptTheme.transitions.create("width", {
    easing: sweptTheme.transitions.easing.sharp,
    duration: sweptTheme.transitions.duration.enteringScreen,
  }),
};

export { sweptTheme, drawerCollapse, drawerExpansion };
