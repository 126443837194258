import { ChannelList } from "stream-chat-react";

import "./ChannelListContainer.css";

export const ChannelListContainer = ({ filters, sort, options }: any) => {
  return (
    <div className="channel-list__container">
      <div className="channel-list__list__wrapper">
        <div className="channel-list__group">
          <ChannelList filters={filters} options={options} sort={sort} />
        </div>
      </div>
    </div>
  );
};
