import { Dialog, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import serverError from "../../assets/images/serverError.png";
import DialogActions from "./DialogActions";
import DialogContent from "./DialogContent";
import DialogTitle from "./DialogTitle";

const useStyles = makeStyles()((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  contentSpacing: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  grey: {
    color: grey[600],
  },
  image: {
    marginBottom: theme.spacing(2),
    width: "360px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  modalContainer: {
    margin: "auto",
    maxWidth: "607px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  red: {
    color: "#FF6666",
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(28),
  },
}));

interface ComponentPropsType {
  open: boolean;
  onDismiss?: () => void;
}

const ModalServerError = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { onDismiss, open } = props;
  return (
    <Dialog
      className={classes.modalContainer}
      onClose={onDismiss}
      aria-labelledby="error-dialog-title"
      open={open}
      PaperProps={{ square: true }}
    >
      <DialogTitle error={true} onDismiss={onDismiss}>
        Error: Couldn't reach server
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom align="center">
          <img
            src={serverError}
            alt="Connection timeout"
            className={classes.image}
          />
        </Typography>
        <Typography
          gutterBottom
          className={classNames(classes.red, classes.contentSpacing)}
        >
          Well, this is awkward. It seems like we were unable to complete your
          request.
        </Typography>
        <Typography
          gutterBottom
          className={classNames(classes.grey, classes.contentSpacing)}
        >
          This can be due to a server issue or a momentary loss of internet
          connection. <span className={classes.bold}>Please try again</span>.
        </Typography>
      </DialogContent>
      <DialogActions onDismiss={onDismiss} closeButtonText="Continue" />
    </Dialog>
  );
};

export default ModalServerError;
