import { useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useChannelStateContext } from "stream-chat-react";

import "./TeamChannelHeader.css";
import MenuButton from "../MenuButton/MenuButton";

export const TeamChannelHeader = () => {
  const { channel, watcher_count } = useChannelStateContext();
  const [muteStatus, setMuteStatus] = useState(channel.muteStatus().muted);

  useEffect(() => {
    getMenuActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muteStatus]);

  const getWatcherText = (watchers: number | undefined) => {
    if (!watchers) return "No users online";
    if (watchers === 1) return "1 user online";
    return `${watchers} users online`;
  };

  const getMenuActions = () => {
    return [
      {
        label: `${muteStatus ? "Unmute" : "Mute"} notifications`,
        onClick: muteStatus ? handleUnmuteChannel : handleMuteChannel,
      },
    ];
  };

  const handleMuteChannel = async () => {
    try {
      await channel.mute();
      setMuteStatus(true);
    } catch (e) {
      console.error("Could not mute channel: ", e);
    }
  };

  const handleUnmuteChannel = async () => {
    try {
      await channel.unmute();
      setMuteStatus(false);
    } catch (e) {
      console.error("Could not unmute channel: ", e);
    }
  };

  return (
    <div className="team-channel-header__container">
      <div className="team-channel-header__right">
        <p className="team-channel-header__name subtext">
          {getWatcherText(watcher_count)}
        </p>

        <MenuButton Icon={MoreVertIcon} actions={getMenuActions()} />
      </div>
    </div>
  );
};
