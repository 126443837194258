import React, { useState } from "react";

import {
  getReadByTooltipText,
  mapToUserNameOrId,
  TooltipUsernameMapper,
  DeliveredCheckIcon,
  LoadingIndicator,
  PopperTooltip,
  AvatarProps,
  useTranslationContext,
  useMessageContext,
  useChatContext,
} from "stream-chat-react";

import { DefaultStreamChatGenerics } from "../../../../types";
import { useEnterLeaveHandlers } from "../../hooks/useEnterLeaveHandlers";
import MessagingAvatar from "../MessagingAvatar/MessagingAvatar";

export type MessageStatusProps = {
  /* Custom UI component to display a user's avatar (overrides the value from `ComponentContext`) */
  Avatar?: React.ComponentType<AvatarProps>;
  /* Message type string to be added to CSS class names. */
  messageType?: string;
  /* Allows to customize the username(s) that appear on the message status tooltip */
  tooltipUserNameMapper?: TooltipUsernameMapper;
};

const UnMemoizedMessageStatus = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageStatusProps
) => {
  const { messageType = "simple", tooltipUserNameMapper = mapToUserNameOrId } =
    props;

  const { handleEnter, handleLeave, tooltipVisible } =
    useEnterLeaveHandlers<HTMLSpanElement>();

  const { client } = useChatContext<StreamChatGenerics>("MessageStatus");
  const { isMyMessage, lastReceivedId, message, readBy, threadList } =
    useMessageContext<StreamChatGenerics>("MessageStatus");
  const { t } = useTranslationContext("MessageStatus");
  const [referenceElement, setReferenceElement] =
    useState<HTMLSpanElement | null>(null);

  if (!isMyMessage() || message.type === "error") return null;

  const justReadByMe =
    readBy &&
    client.user &&
    readBy.length === 1 &&
    readBy[0] &&
    readBy[0].id === client.user.id;
  const rootClassName = `str-chat__message-${messageType}-status str-chat__message-status`;

  if (message && message.status === "sending") {
    return (
      <span
        className="str-chat__message-team-status"
        data-testid="message-team-sending"
      >
        <PopperTooltip
          offset={[0, 5]}
          referenceElement={referenceElement}
          visible={tooltipVisible}
        >
          {t<string>("Sending...")}
        </PopperTooltip>
        <LoadingIndicator />
      </span>
    );
  }

  if (readBy && readBy.length !== 0 && !threadList && !justReadByMe) {
    const readByOthers = readBy
      .filter((item) => item && client.user && item.id !== client.user.id)
      .sort((a: any, b: any) => a.updated_at - b.updated_at);

    const lastReadUser = readByOthers.slice(-1)[0];
    const isReadByMe = readByOthers.length < readBy.length;
    const readPlusNumber = isReadByMe
      ? readBy.length > 2
        ? readBy.length - 2
        : 0
      : readBy.length > 1
      ? readBy.length - 1
      : 0;
    const userType = lastReadUser ? lastReadUser.swept_type : 0;

    return (
      <span
        className={rootClassName}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        ref={setReferenceElement}
        data-testid="message-team-read-avatar"
      >
        <PopperTooltip
          offset={[0, 5]}
          referenceElement={referenceElement}
          visible={tooltipVisible}
        >
          {getReadByTooltipText(readBy, t, client, tooltipUserNameMapper)}
        </PopperTooltip>
        <MessagingAvatar
          userName={lastReadUser.name || ""}
          userType={userType}
          size={"15"}
        />
        {readPlusNumber > 0 && (
          <span
            className="str-chat__message-team-status-number"
            data-testid="message-team-read-by-count"
          >
            +{readPlusNumber}
          </span>
        )}
      </span>
    );
  } else if (
    message &&
    message.status === "received" &&
    message.id === lastReceivedId &&
    !threadList
  ) {
    return (
      <span
        className="str-chat__message-team-status"
        data-testid="message-team-received"
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        ref={setReferenceElement}
      >
        <PopperTooltip
          offset={[0, 5]}
          referenceElement={referenceElement}
          visible={tooltipVisible}
        >
          {t<string>("Delivered")}
        </PopperTooltip>
        <div className="str-chat__delivered_check_icon">
          <DeliveredCheckIcon />
        </div>
      </span>
    );
  }

  return null;
};

export const MessageStatus = React.memo(
  UnMemoizedMessageStatus
) as typeof UnMemoizedMessageStatus;
