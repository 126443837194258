import { Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { Moment } from "moment-timezone";
import { makeStyles } from "tss-react/mui";

import { formatMomentWithTimezone } from "../../../helpers";

const useStyles = makeStyles()((theme) => ({
  cardBody: {
    border: "1px solid #F2F2F2",
    "& .MuiCardContent-root": {
      paddingLeft: theme.spacing(2),
    },
  },
  cardHeader: {
    borderBottom: theme.divider.default,
    color: theme.palette.text.cardHeader,
    fontSize: theme.typography.pxToRem(20),
  },
}));

interface InspectionDetailsCardProps {
  completedOn: Moment | null;
  completedBy: string;
  locationName: string;
  locationTimezone: string;
}

const InspectionDetailsCard = (props: InspectionDetailsCardProps) => {
  const { completedOn, completedBy, locationName, locationTimezone } = props;
  const { classes } = useStyles();
  const dateInputFormat = "ddd MMM D, YYYY";
  const showTimezone = true;
  const formatedCompletedOn = completedOn
    ? formatMomentWithTimezone(
        completedOn,
        locationTimezone,
        dateInputFormat,
        showTimezone
      )
    : "";

  return (
    <Card className={classes.cardBody}>
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title="Inspection Details"
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label="Location"
              value={locationName}
              variant="outlined"
              data-testid={"input-location"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label="Date"
              value={formatedCompletedOn}
              variant="outlined"
              data-testid={"input-date"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label="Completed By"
              value={completedBy}
              variant="outlined"
              data-testid={"input-completed-by"}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InspectionDetailsCard;
