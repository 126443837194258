import React, { useContext, useState, useEffect, useRef } from "react";

import { Divider, Grid, Typography, debounce } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import {
  APPBAR_HEIGHT,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  DRAWER_BREAKPOINT,
  MAX_PAGE_WIDTH,
} from "../../constants";
import { DrawerContext } from "../../helpers";
import { drawerCollapse, drawerExpansion } from "../../theme";
import OverflowTooltip from "../shared/OverflowTooltip";
import SweptCrumbs from "./SweptCrumbs";

const useStyles = makeStyles()((theme) => ({
  breadcrumbs: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  childContainer: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  stickyContainer: {
    backgroundColor: theme.palette.background.default,
    width: "100vw",
    position: "fixed",
    top: APPBAR_HEIGHT,
    left: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  stickyContainerDrawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      transition: drawerCollapse.transitionLeft,
      width: `calc(100vw - ${DRAWER_WIDTH_COLLAPSED}px)`,
      left: DRAWER_WIDTH_COLLAPSED,
    },
  },
  stickyContainerDrawerExpanded: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      transition: drawerExpansion.transitionLeft,
      width: `calc(100vw - ${DRAWER_WIDTH_EXPANDED}px)`,
      left: DRAWER_WIDTH_EXPANDED,
    },
  },
  stickyContent: {
    width: `calc(100vw - ${theme.spacing(3)})`,
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      maxWidth: `calc(${MAX_PAGE_WIDTH} - ${theme.spacing(8)})`,
    },
  },
  stickyContentDrawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      width: `calc(100vw - ${DRAWER_WIDTH_COLLAPSED}px - ${theme.spacing(8)})`,
    },
  },
  stickyContentDrawerExpanded: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      width: `calc(100vw - ${DRAWER_WIDTH_EXPANDED}px - ${theme.spacing(8)})`,
    },
  },
  stickyContentPadding: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      paddingLeft: theme.spacing(4),
    },
  },
  title: {
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  titleContainer: {
    flexGrow: 2,
    minWidth: "1px",
  },
}));

interface ComponentPropsType {
  title: string;
  crumbs?: {
    label: string;
    to?: string;
  }[];
  children?: React.ReactNode;
}

const StickyPageHeader = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { title, crumbs } = props;
  const children = React.Children.toArray(props.children);
  let isBrowserDrawerExpanded = useContext(DrawerContext);
  const headerRef = useRef<HTMLDivElement | any>(null);
  const [spacerHeight, setSpacerHeight] = useState("");
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    let isCancelled = false;
    const updateSize = debounce(function () {
      const newSpacerHeight = headerRef.current
        ? headerRef.current.clientHeight
        : 0;
      if (!isCancelled) {
        setSpacerHeight(newSpacerHeight + "px");
      }
    }, 250);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => {
      isCancelled = true;
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <>
      <div
        className={classNames(
          classes.stickyContainer,
          isBrowserDrawerExpanded
            ? classes.stickyContainerDrawerExpanded
            : classes.stickyContainerDrawerCollapsed
        )}
        ref={headerRef}
      >
        <div className={classes.stickyContentPadding}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            className={classNames(
              classes.stickyContent,
              isBrowserDrawerExpanded
                ? classes.stickyContentDrawerExpanded
                : classes.stickyContentDrawerCollapsed
            )}
          >
            {crumbs && (
              <Grid item xs={12} className={classes.breadcrumbs}>
                <SweptCrumbs crumbs={crumbs} />
              </Grid>
            )}
            <Grid
              className={classes.titleContainer}
              item
              xs={12}
              sm={props.children ? true : 12}
            >
              <Typography className={classes.title} variant={"h1"}>
                <OverflowTooltip title={title}>{title}</OverflowTooltip>
              </Typography>
            </Grid>
            {isLargeScreen ? (
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                xs
              >
                {props.children &&
                  children.map((child, index) => (
                    <Grid item key={index}>
                      {child}
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                className={classes.childContainer}
              >
                {props.children &&
                  children.map((child, index) => (
                    <Grid item key={index}>
                      {child}
                    </Grid>
                  ))}
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ height: spacerHeight }}></div>
    </>
  );
};

export default StickyPageHeader;
