import { Route, Routes } from "react-router-dom";

import { AppFrameWrapper } from "../AppFrame";
import DashBoardPage from "../dashboard/Dashboard";
import MessageInboxView from "../messaging/components/MessageInboxView";
import InspectionInstanceDetails from "../reports/inspections/InspectionInstanceDetails";
import InspectionInstanceReport from "../reports/inspections/InspectionsReport";
import NotFound from "./NotFound";

const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/dashboard" element={<DashBoardPage />} />
        <Route path="/messaging" element={<MessageInboxView />} />
        <Route
          path="/inspections/:inspectionId"
          element={<InspectionInstanceDetails />}
        />
        <Route path="/inspections" element={<InspectionInstanceReport />} />
        <Route path="/" element={<MessageInboxView />} />
        <Route element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppFrameWrapper(AuthRoutes);
