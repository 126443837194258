import Avatar from "react-avatar";

import { UserType } from "../../../../constants";

interface ComponentPropsType {
  size?: string;
  userName: string;
  userType: any;
}

const MessagingAvatar = (props: ComponentPropsType) => {
  const { size = "32", userName, userType } = props;

  const getColorForUserType = () => {
    let color;
    switch (userType) {
      case UserType.MANAGER:
        color = "#FFA200";
        break;
      case UserType.SUPERVISOR:
        color = "#009EEF";
        break;
      case UserType.CLEANER:
        color = "#00C67F";
        break;
      case UserType.CLIENT:
        color = "#1D557B";
        break;
      default:
        color = "#B0BEC5";
        break;
    }
    return color;
  };

  return (
    <Avatar
      name={userName}
      size={size}
      round
      textSizeRatio={2.3}
      color={getColorForUserType()}
      maxInitials={2}
      style={{
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};

export default MessagingAvatar;
