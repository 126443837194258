import { Fragment, useState, useRef } from "react";

import { ClickAwayListener } from "@mui/base";
import { Button, ButtonGroup } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import OverflowTooltip from "../../../shared/OverflowTooltip";

interface ActionObjectType {
  label: string;
  onClick: Function;
}

interface ComponentPropsType {
  Icon: Function;
  actions: ActionObjectType[];
}

const MenuButton = (props: ComponentPropsType) => {
  const { Icon, actions } = props;

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup ref={anchorRef}>
        <Button
          size="small"
          style={{ border: "none" }}
          data-testid={"menu-icon-button"}
          onClick={handleToggle}
        >
          <Icon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <MenuList>
              {actions.map((oneAction) => (
                <MenuItem
                  key={oneAction.label}
                  onClick={() => {
                    handleClose();
                    oneAction.onClick();
                  }}
                >
                  <OverflowTooltip title={oneAction.label}>
                    <span
                      data-testid={oneAction.label
                        .toLowerCase()
                        .replaceAll(" ", "_")}
                    >
                      {oneAction.label}
                    </span>
                  </OverflowTooltip>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Fragment>
  );
};

export default MenuButton;
