import { useState } from "react";

import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Button, InputAdornment, TextFieldProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import TextFieldWithValidation from "./TextFieldWithValidation";

const useStyles = makeStyles()((theme: any) => ({
  root: {
    "& input::placeholder": {
      color: "#FFF",
    },
    "& .Mui-error + .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    },
  },
  showButton: {
    color: theme.palette.text.blurred,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 24,
    zoom: 0.8,
  },
}));

interface ComponentPropsType {
  type: string;
  value: string;
  inputId?: string;
  variant?: "standard" | "outlined" | "filled";
  inviteForm?: boolean;
  userForm?: boolean;
  shrinkLabel?: boolean;
}

const PasswordFieldWithValidation = (
  props: ComponentPropsType & TextFieldProps
) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { classes } = useStyles();
  const {
    type,
    value,
    placeholder = "password",
    variant = "standard",
    ...rest
  } = props;

  return (
    <TextFieldWithValidation
      {...rest}
      className={classes.root}
      type={showPassword ? "text" : "password"}
      value={value}
      inputProps={{ placeholder: placeholder }}
      variant={variant}
      showPasswordButton={
        <InputAdornment position="end">
          <Button
            aria-label="Toggle password visibility"
            className={classes.showButton}
            onClick={toggleShowPassword}
            size="small"
            variant="text"
          >
            {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </Button>
        </InputAdornment>
      }
    />
  );
};

export default PasswordFieldWithValidation;
