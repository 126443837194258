import { useEffect, useRef, useState } from "react";

import { Card, CardContent, CardHeader, Grid, debounce } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { InspectionRating } from "../../../constants";
import InspectionPointListItem from "./InspectionPointListItem";

const useStyles = makeStyles()((theme) => ({
  buttonPlaceholder: {
    width: "56px",
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    borderBottom: theme.divider.default,
    color: theme.palette.text.cardHeader,
    fontSize: theme.typography.pxToRem(20),
  },
  inspectionPointHeader: {
    fontSize: 14,
    fontWeight: 700,
    color: "rgb(51, 51, 51, 0.7)",
    fontFamily: ["OpenSans-Bold", "Open Sans Bold", "Open Sans"].join(","),
    borderBottom: theme.divider.default,
    minHeight: "54px",
    paddingLeft: theme.spacing(1),
  },
  rating: {
    textAlign: "center",
    width: "90px",
  },
}));

interface ComponentPropsType {
  id: number;
  name: string;
  points: {
    comment?: string;
    description: string;
    id: number;
    index: number;
    isHeader: boolean;
    name: string;
    photos?: {
      id: number;
    }[];
    rating: InspectionRating;
  }[];
}

const InspectionAreaCard = (props: ComponentPropsType) => {
  const { id, name, points } = props;
  const { classes } = useStyles();
  const cardRef = useRef<HTMLDivElement | any>(null);

  const [isSmallView, setIsSmallView] = useState(false);
  const threshold = 500;

  useEffect(() => {
    let isCancelled = false;

    const updateSize = debounce(function () {
      const width = cardRef.current ? cardRef.current.clientWidth : 0;
      if (!isCancelled && width <= threshold) {
        setIsSmallView(true);
      } else {
        setIsSmallView(false);
      }
    }, 250);

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      isCancelled = true;
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        ref={cardRef}
        title={name}
      />
      <CardContent className={classes.cardContent}>
        <Grid
          className={classes.inspectionPointHeader}
          container
          alignItems="center"
          key={id}
        >
          <Grid item xs>
            {"Inspection Point"}
          </Grid>
          {!isSmallView && (
            <>
              <Grid className={classes.rating} item>
                {"Exceeds"}
              </Grid>
              <Grid className={classes.rating} item>
                {"Meets"}
              </Grid>
              <Grid className={classes.rating} item>
                {"Below"}
              </Grid>
              <Grid className={classes.rating} item>
                {"N/A"}
              </Grid>
            </>
          )}
          <Grid className={classes.buttonPlaceholder} item></Grid>
        </Grid>
        {points.map((point) => (
          <InspectionPointListItem
            key={point.id}
            {...point}
            isSmallView={isSmallView}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default InspectionAreaCard;
