import { TableRow, TableCell } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import { DRAWER_BREAKPOINT } from "../../constants";
import { columnObj } from "../../types";

const useStyles = makeStyles()((theme) => ({
  noClick: {
    cursor: "initial",
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#E0F8FF",
    },
  },
}));

interface TableCellRendererPropsType {
  column: columnObj;
  row: any;
  onRowClick?: (row: any) => void;
}

const TableCellRenderer = (props: TableCellRendererPropsType) => {
  const { classes } = useStyles();
  const { column, row, onRowClick } = props;
  return (
    <TableCell
      data-testid={`table-column-${row.id}-${row[column.dataKey]}`}
      key={row[column.dataKey]}
      align={column.align ? column.align : "left"}
      className={classNames(classes.tableCell, {
        [classes.noClick]: onRowClick == null,
      })}
      variant="body"
      style={{ padding: column.padding, width: column.width }}
    >
      {column.cellContentRenderer
        ? column.cellContentRenderer({
            cellData: row[column.dataKey],
            rowData: row,
          })
        : row[column.dataKey]}
    </TableCell>
  );
};

interface TableRowsPropsType {
  columnsLarge: columnObj[];
  columnsSmall: columnObj[];
  row: any;
  onRowClick?: (row: any) => void;
  rowClassName?: string;
}

const TableRows = (props: TableRowsPropsType) => {
  const { classes } = useStyles();
  const { onRowClick, row, columnsLarge, columnsSmall, rowClassName } = props;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(DRAWER_BREAKPOINT));
  return (
    <TableRow
      onClick={onRowClick ? () => onRowClick(row) : () => {}}
      className={classNames(classes.tableRow, rowClassName, {
        [classes.tableRowHover]: onRowClick,
      })}
    >
      {isLargeScreen
        ? columnsLarge &&
          columnsLarge.map((column) => (
            <TableCellRenderer
              key={`column_${column.dataKey}_large`}
              row={row}
              column={column}
              onRowClick={onRowClick}
            />
          ))
        : columnsSmall &&
          columnsSmall.map((column) => (
            <TableCellRenderer
              key={`column_${column.dataKey}_small`}
              row={row}
              column={column}
              onRowClick={onRowClick}
            />
          ))}
    </TableRow>
  );
};

export default TableRows;
