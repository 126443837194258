import { TableCell, TableHead, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import { DRAWER_BREAKPOINT } from "../../constants";
import { columnObj } from "../../types";

const useStyles = makeStyles()((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "flex-end",
  },
  flexItem: {
    flexDirection: "row-reverse",
    whiteSpace: "nowrap",
  },
  noClick: {
    cursor: "initial",
  },
  stickyBorder: {
    border: "none",
  },
  tableHeaderCell: {
    whiteSpace: "break-spaces",
    fontWeight: "bold",
  },
  tableTotalsRowCell: {
    fontWeight: "initial",
    "&:first-of-type > div": {
      borderBottomLeftRadius: "10px",
      borderTopLeftRadius: "10px",
      fontWeight: theme.typography.fontWeightBold,
      margin: "-10px -15px -10px -10px",
      padding: "10px 15px 10px 10px",
    },
    "&:last-child > div": {
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px",
      margin: "-10px -10px -10px -15px",
      padding: "10px 10px 10px 15px",
    },
  },
  tableTotalsRowInnerCell: {
    background: "#EAEAEA",
    margin: "-10px -15px",
    overflow: "hidden",
    padding: "10px 15px",
    textAlign: "right",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

interface TableColGroupPropsType {
  columns: Array<columnObj>;
}

const TableColGroup = (props: TableColGroupPropsType) => {
  // Tells the table how wide each column is so `colspan` works on first row
  return (
    <colgroup>
      {props.columns.map((column) => (
        <col key={column.dataKey} style={{ width: column.width }}></col>
      ))}
    </colgroup>
  );
};

interface TableHeadersRowPropsType {
  columns: Array<columnObj>;
  sticky: boolean;
}

const TableTotalsRow = (props: TableHeadersRowPropsType) => {
  const { classes } = useStyles();
  const { columns, sticky } = props;

  let colSpan = 1;
  const totalColumns = [];
  const numberOfColumns = columns.length;
  let spanOfTotalsColumns = 0;
  for (let i = 0; i < numberOfColumns; i++) {
    if (columns[i].hasOwnProperty("total")) {
      if (!totalColumns.length) {
        // This assumes the first column will never be a total
        spanOfTotalsColumns += --colSpan;
        totalColumns.push({ colSpan, dataKey: "startTotals", value: "Totals" });
        colSpan = 1;
      }
      spanOfTotalsColumns += colSpan;
      totalColumns.push({
        colSpan,
        dataKey: columns[i].dataKey,
        value: `${columns[i].total}\u200b`, // enforce padding when total is an empty string
      });
      colSpan = 1;
    } else {
      colSpan++;
    }
  }
  if (spanOfTotalsColumns < numberOfColumns) {
    totalColumns.push({
      colSpan: numberOfColumns - spanOfTotalsColumns,
      dataKey: "endTotals",
      value: "\u200b", // unicode zero width space character
    });
  }

  return (
    <TableRow id={`table-totals-row${sticky ? "-sticky" : ""}`}>
      {totalColumns.map((column) => {
        const id = `table-totals-column-${column.dataKey}${
          sticky ? "-sticky" : ""
        }`;
        return (
          <TableCell
            classes={{ root: classes.tableTotalsRowCell }}
            colSpan={column.colSpan}
            data-testid={id}
            id={id}
            key={id}
          >
            <div className={classes.tableTotalsRowInnerCell}>
              {column.value}
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

interface TableHeadersRenderPropsType {
  column: columnObj;
  sticky: boolean;
  id: string;
}

const TableHeaderRenderer = (props: TableHeadersRenderPropsType) => {
  const { classes } = useStyles();
  const { column, id, sticky } = props;

  return (
    <TableCell
      aria-label={id}
      id={id}
      key={column.label}
      className={classNames(classes.tableHeaderCell, classes.noClick, {
        [classes.stickyBorder]: sticky,
      })}
      variant="head"
      align={column.align ? column.align : "left"}
      style={{ padding: column.padding, width: column.width }}
    >
      <div
        className={classNames({
          [classes.flexContainer]: column.align === "right",
        })}
      >
        <div
          className={classNames({
            [classes.flexItem]: column.align === "right",
          })}
        >
          {column.label}
        </div>
      </div>
    </TableCell>
  );
};

const TableHeadersRow = (props: TableHeadersRowPropsType) => {
  const { columns, sticky } = props;
  return (
    <TableRow>
      {columns &&
        columns.map((column) => {
          const id = `table-${sticky ? "sticky-" : ""}column-${column.dataKey}`;
          return (
            <TableHeaderRenderer
              aria-label={id}
              id={id}
              key={id}
              column={column}
              sticky={sticky}
            />
          );
        })}
    </TableRow>
  );
};

interface TableHeaderPropsType {
  columnsLarge: Array<columnObj>;
  columnsSmall: Array<columnObj>;
  sticky: boolean;
}

const TableHeader = (props: TableHeaderPropsType) => {
  const theme = useTheme();
  const { columnsLarge, columnsSmall, sticky } = props;

  const showTotalsLarge = Boolean(
    columnsLarge.filter((column) => column.hasOwnProperty("total")).length
  );
  const showTotalsSmall = Boolean(
    columnsSmall.filter((column) => column.hasOwnProperty("total")).length
  );
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(DRAWER_BREAKPOINT));

  return (
    <>
      {showTotalsLarge && isLargeScreen && (
        <TableColGroup columns={columnsLarge} />
      )}
      {showTotalsSmall && !isLargeScreen && (
        <TableColGroup columns={columnsSmall} />
      )}
      <TableHead>
        {showTotalsLarge && isLargeScreen && (
          <TableTotalsRow columns={columnsLarge} sticky={sticky} />
        )}
        {showTotalsSmall && !isLargeScreen && (
          <TableTotalsRow columns={columnsSmall} sticky={sticky} />
        )}
        <TableHeadersRow
          columns={isLargeScreen ? columnsLarge : columnsSmall}
          sticky={sticky}
        />
      </TableHead>
    </>
  );
};

export default TableHeader;
