const APPBAR_HEIGHT = 64;
const CLIENT_LOGIN_METHOD = 5; // login method for client portal
const DRAWER_BREAKPOINT = "md";
const DRAWER_WIDTH_COLLAPSED = 72;
const DRAWER_WIDTH_EXPANDED = 210;
const EMAIL_REGEX = /.+@.+/; // minimum email requirement: *@*
const FILE_NAME_DATE_FORMAT = "YYYY-MM-DD"; // for downloading reports
const MAX_INPUT_LENGTH = 85; // maximum string length that will not get truncated in varchar(255) utf-8
const MAX_PAGE_WIDTH = "1200px";
const PAGINATE_RESULTS_PER_PAGE = 100;

const UserType = {
  SUPER_ADMIN: 0,
  MANAGER: 1,
  CLEANER: 2,
  CLIENT: 3,
  SUPERVISOR: 4,
  DISABLED: 10,
};

enum InspectionRating {
  NOT_SET = 0,
  EXCEEDS_STANDARD = 1,
  MEETS_STANDARD = 2,
  BELOW_STANDARD = 3,
  NOT_INSPECTED = 4,
}

export {
  APPBAR_HEIGHT,
  CLIENT_LOGIN_METHOD,
  DRAWER_BREAKPOINT,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  EMAIL_REGEX,
  FILE_NAME_DATE_FORMAT,
  MAX_INPUT_LENGTH,
  MAX_PAGE_WIDTH,
  PAGINATE_RESULTS_PER_PAGE,
  InspectionRating,
  UserType,
};
