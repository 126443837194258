import { useEffect, useState } from "react";

import { Button, Dialog, Grid, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import sweptBlueLogo from "../../assets/images/sweptBlueLogo.png";
import {
  apiRequest,
  customNavigate,
  formatApiRequestError,
} from "../../helpers";
import { validatePasswordHelper } from "../../validationHelpers";
import PasswordFieldWithValidation from "../inputs/PasswordFieldWithValidation";
import DialogActions from "../modals/DialogActions";
import DialogContent from "../modals/DialogContent";
import DialogTitle from "../modals/DialogTitle";

const useStyles = makeStyles()((theme: any) => ({
  backdrop: {
    backgroundColor: theme.palette.background.default,
    textAlign: "center",
    padding: theme.spacing(1),
  },
  container: {
    maxWidth: "450px",
    margin: `${theme.spacing(2)} auto`,
    justifyContent: "center",
  },
  footnote: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(4),
    fontSize: theme.typography.pxToRem(12),
  },
  link: {
    color: theme.palette.secondary.main,
  },
  logo: {
    marginTop: theme.spacing(8),
    width: "170px",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  modalContainer: {
    margin: "auto",
    maxWidth: "703px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textFieldFullWidth: {
    marginTop: theme.spacing(2),
  },
}));

const InvitePage = () => {
  const { classes } = useStyles();
  const [params] = useSearchParams();

  const id = Number(params.get("var1")) || 0;
  const token = params.get("var2");

  const dataObj = {
    value: "",
    error: false,
    helperText: "",
  };
  const [companyName, setCompanyName] = useState(" ");
  const [confirmPassword, setConfirmPassword] = useState({ ...dataObj });
  const [fullName, setFullName] = useState(" ");
  const [password, setPassword] = useState({ ...dataObj });
  const [isLoading, setIsLoading] = useState(true);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const [showPasswordSavedScreen, setShowPasswordSavedScreen] = useState(false);

  const getTokenAbortController = new AbortController();
  const savePasswordAbortController = new AbortController();

  useEffect(() => {
    checkPageToken();

    return () => {
      getTokenAbortController.abort();
      savePasswordAbortController.abort();
    };

    // eslint-disable-next-line
  }, [id, token]);

  const checkPageToken = () => {
    apiRequest({
      method: "GET",
      endpoint: "create-password",
      params: { id: id, token: token },
      signal: getTokenAbortController.signal,
    })
      .then((response: any) => {
        setCompanyName(response.companyName);
        setFullName(response.fullName);
      })
      .catch((error) => {
        setShowTokenExpiredModal(true);
        console.warn(
          "User ID or token is invalid or expired:",
          formatApiRequestError(error)
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const savePassword = () => {
    if (
      password.error ||
      confirmPassword.error ||
      password.value !== confirmPassword.value
    ) {
      return;
    }

    apiRequest({
      method: "PUT",
      endpoint: "create-password/" + id,
      body: { password: password.value, token: token },
      signal: savePasswordAbortController.signal,
    })
      .then(() => {
        setShowPasswordSavedScreen(true);
        setTimeout(function () {
          customNavigate("/");
        }, 5000);
      })
      .catch((error) => {
        console.warn(
          "Could not set the user password:",
          formatApiRequestError(error)
        );
      });
  };

  const validatePassword = (event: any) => {
    const newPassword = event.target.value.trim();
    setPassword(validatePasswordHelper(newPassword));
    if (confirmPassword.value !== newPassword) {
      setConfirmPassword({ ...confirmPassword, error: true });
    }
  };

  const validateConfirmPassword = (event: any) => {
    const value = event.target.value.trim();
    const newPassword = {
      ...confirmPassword,
      value,
      error: value !== password.value,
      helperText:
        value !== password.value ? "Must be the same as the password." : "",
    };

    setConfirmPassword(newPassword);
  };

  return (
    <>
      {!isLoading && (
        <div className={classes.backdrop}>
          <img src={sweptBlueLogo} alt="Swept" className={classes.logo} />
          <Grid className={classes.container} container>
            {!showTokenExpiredModal && !showPasswordSavedScreen && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" noWrap>
                    Welcome to {companyName},
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {fullName}
                  </Typography>
                </Grid>
                <Typography variant="body2" className={classes.margin}>
                  Please enter your password below to complete the signup
                  process
                </Typography>
                <Grid item xs={12}>
                  <PasswordFieldWithValidation
                    id="password"
                    inputId="password"
                    placeholder="Your Password"
                    inputProps={{ "data-testid": "password" }}
                    label="Your Password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    className={classes.textFieldFullWidth}
                    onChange={validatePassword}
                    error={password.error}
                    helperText={
                      password.error
                        ? "Invalid password: must be 6-85 characters long including an uppercase and lowercase letter, a number and a special character"
                        : "Must be 6-85 characters long including an uppercase and lowercase letter, a number and a special character."
                    }
                    fullWidth
                    value={password.value}
                    inviteForm={true}
                    variant="outlined"
                    shrinkLabel={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordFieldWithValidation
                    id="confirm-password"
                    inputId="confirm-password"
                    placeholder="Confirm Your Password"
                    inputProps={{ "data-testid": "confirm-password" }}
                    label="Confirm Your Password"
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirm-password"
                    className={classes.textFieldFullWidth}
                    onChange={validateConfirmPassword}
                    error={confirmPassword.error}
                    helperText={confirmPassword.helperText}
                    fullWidth
                    value={confirmPassword.value}
                    inviteForm={true}
                    variant="outlined"
                    shrinkLabel={true}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Button
                    onClick={savePassword}
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </Grid>
                <Typography className={classes.footnote}>
                  {
                    "By creating an account in Swept you are agreeing to the Swept "
                  }
                  <a
                    href="https://www.sweptworks.com/terms-and-conditions/"
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  {" and "}
                  <a
                    href="https://www.sweptworks.com/privacy-policy/"
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </>
            )}

            {showPasswordSavedScreen && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Congratulations! <br />
                    Your account setup is complete now, you will be redirected
                    to the sign-in page shortly. Click{" "}
                    <Link href="/">here</Link> if you encounter any issues.
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      )}

      <Dialog
        className={classes.modalContainer}
        fullWidth
        onClose={() => customNavigate("/")}
        open={showTokenExpiredModal}
        PaperProps={{ square: true }}
      >
        <DialogTitle onDismiss={() => customNavigate("/")}>
          Whoops, that's an expired link
        </DialogTitle>
        <DialogContent>
          <Typography>
            For security reasons, password setup links expire after 15 days.
            Please contact your admin to request a new password setup link.
          </Typography>
        </DialogContent>
        <DialogActions
          onDismiss={() => customNavigate("/")}
          closeButtonText={"Okay"}
        />
      </Dialog>
    </>
  );
};

export default InvitePage;
