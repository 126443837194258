import { Channel } from "stream-chat-react";

import { ChannelEmptyState } from "../../components/ChannelEmptyState/ChannelEmptyState";
import "./ChannelContainer.css";
import { MAX_NUMBER_OF_FILES } from "../../constant";
import { TeamMessageInput } from "../TeamMessageInput/TeamMessageInput";
import { ChannelInner } from "./ChannelInner";

export const ChannelContainer = () => {
  return (
    <div className="channel__container">
      <Channel
        Input={TeamMessageInput}
        EmptyStateIndicator={ChannelEmptyState}
        maxNumberOfFiles={MAX_NUMBER_OF_FILES}
      >
        <ChannelInner />
      </Channel>
    </div>
  );
};
