import { Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function NotFound() {
  const { classes } = useStyles();
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className={classes.root}>
      <Typography variant="h4">Well That's Embarrassing!</Typography>
      <Typography variant="subtitle1">
        Seems we've misplaced what you're looking for.
      </Typography>
    </div>
  );
}
