import React from "react";

import { Search, Clear } from "@mui/icons-material";
import { Button, Input, InputAdornment } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import { SearchInputPropType } from "types";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    color: theme.palette.text.grey,
    fontSize: theme.typography.pxToRem(20),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": { backgroundColor: theme.palette.background.hover },
  },
  default: {
    ...theme.border.onFocus,
  },
  outlined: {
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.fontSize,
  },
  buttonStyle: {
    color: theme.palette.text.grey,
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const SearchField = (props: Partial<SearchInputPropType>) => {
  const { classes } = useStyles();
  const {
    disabled,
    submittedText,
    modifiedText,
    onChange,
    placeholder,
    onSubmit,
  } = props;

  const isModified = modifiedText !== null;
  const currentValue = isModified ? modifiedText : submittedText;

  const handleIconClick = () => {
    // submit or clear, based on whether the text has been modified
    const toSubmit = isModified ? currentValue : "";
    onSubmit?.(toSubmit);
  };

  const handleChange = (event: React.ChangeEvent) => {
    onChange?.((event.target as HTMLInputElement).value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSubmit?.((event.target as HTMLInputElement).value);
    }
  };

  return (
    <Input
      className={classNames(classes.root, classes.default)}
      fullWidth
      disabled={disabled}
      disableUnderline
      placeholder={placeholder}
      value={currentValue || ""}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      endAdornment={
        <InputAdornment position="end">
          <Button
            variant="contained"
            size="small"
            onClick={handleIconClick}
            className={classes.buttonStyle}
            data-testid={"search-button"}
          >
            {isModified || !currentValue ? <Search /> : <Clear />}
          </Button>
        </InputAdornment>
      }
    />
  );
};

SearchField.defaultProps = {
  disabled: false,
};

export default SearchField;
