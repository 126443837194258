import { useContext, useState } from "react";

import { Forum, Menu } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Badge,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import Avatar from "react-avatar";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import sweptLogo from "../../assets/images/swept.png";
import { APPBAR_HEIGHT, DRAWER_BREAKPOINT } from "../../constants";
import { UserContext } from "../../helpers";
import { drawerCollapse, drawerExpansion } from "../../theme";
import { AppbarPropsType } from "../../types";
import AppBarDropdown from "./AppBarDropdown";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    background: theme.palette.background.main,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarMargin: {
    borderBottom: "none",
    height: APPBAR_HEIGHT,
    marginBottom: 0,
  },
  avatar: {
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  badge: {
    backgroundColor: theme.palette.background.error,
    borderRadius: "4px",
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
    lineHeight: 1,
    padding: "2px 5px",
    position: "absolute",
    top: "2px",
    left: "2px",
    right: "auto",
    width: "auto",
    height: "auto",
  },
  image: {
    width: 30,
    height: 30,
  },
  logoContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      cursor: "pointer", // onClick is set only on the large screen
    },
    paddingRight: 40,
  },
  logo: {
    width: 100,
  },
  menuImgContainer: {
    height: "100%",
    background: theme.palette.primary.main,
    display: "flex",
    paddingLeft: 16,
    paddingRight: 16,
    width: 78,
    justifyContent: "center",
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      cursor: "pointer", // onClick is set only on the large screen
    },
  },
  menuImgContainerOpen: {
    transition: drawerExpansion.transitionWidth,
  },
  menuImgContainerClose: {
    transition: drawerCollapse.transitionWidth,
  },
  menuImg: {
    height: 64,
    fontSize: "2rem",
    transform: "rotate(0deg)",
    transition: "transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  notificationButton: {
    color: "#C3CCDD",
    fontSize: theme.typography.pxToRem(30),
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    marginLeft: "6px",
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  title: {
    flexGrow: 1,
    color: "#C7D5DE",
    fontSize: theme.typography.pxToRem(24),
    fontStyle: "italic",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1,
    paddingLeft: "15px",
  },
  toolBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: 0,
    paddingLeft: 0,
  },
}));

const AppBarFull = (props: AppbarPropsType) => {
  const {
    badgeCounts,
    isBrowserDrawerExpanded,
    onClickAvatar,
    onLogoutUser,
    onMouseEnteredDropdown,
    onMouseLeftAvatar,
    onMouseLeftDropdown,
    onToggleBrowserDrawer,
    showSettingsDropdown,
  } = props;
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { classes } = useStyles();
  const [rotateIcon, setRotateIcon] = useState(false);

  const rotateToggleBrowserDrawer = () => {
    setRotateIcon(!rotateIcon);
    onToggleBrowserDrawer();
  };

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar id="drawer-toggle-button" className={classes.toolBar}>
        <div className={classes.appBarMargin}>
          <div
            className={classNames(classes.menuImgContainer, {
              [classes.menuImgContainerOpen]: isBrowserDrawerExpanded,
              [classes.menuImgContainerClose]: !isBrowserDrawerExpanded,
            })}
            onClick={rotateToggleBrowserDrawer}
          >
            <Menu
              className={classNames(classes.menuImg, {
                [classes.rotateIcon]: rotateIcon,
              })}
              aria-label={"Toggle menu"}
            />
          </div>
        </div>
        <Link id="app-bar-swept-logo" className={classes.logoContainer} to="/">
          <img className={classes.logo} src={sweptLogo} alt="Swept" />
        </Link>
        <Typography
          id="app-bar-company-name"
          className={classes.title}
          color="inherit"
          variant="h6"
        >
          {user.company.name}
        </Typography>
        <IconButton
          id="app-bar-messages"
          data-testid="app-bar-messages"
          className={classes.notificationButton}
          color="inherit"
          onClick={() => navigate("/messaging")}
        >
          <Badge
            id="badge-count-messages"
            badgeContent={badgeCounts}
            classes={{ badge: classes.badge }}
          >
            <Forum fontSize="inherit" />
          </Badge>
        </IconButton>
        <IconButton
          id="app-bar-avatar"
          onMouseLeave={onMouseLeftAvatar}
          onClick={onClickAvatar}
        >
          <Avatar
            name={user.firstName.split(" ")[0] + " " + user.lastName}
            size="36"
            round
            textSizeRatio={1.7}
            color="#009eef"
            maxInitials={2}
          />
        </IconButton>
      </Toolbar>
      <AppBarDropdown
        onMouseLeftDropdown={onMouseLeftDropdown}
        onMouseEnteredDropdown={onMouseEnteredDropdown}
        showSettingsDropdown={showSettingsDropdown}
        onLogoutUser={onLogoutUser}
      />
    </MuiAppBar>
  );
};

export default AppBarFull;
