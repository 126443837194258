import { useEffect, useRef, useState } from "react";

import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";

interface OverflowTooltipType {
  children?: JSX.Element | string;
  show?: boolean;
  title: string;
  other?: object;
}

const useStyles = makeStyles()({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const OverflowTooltip = (props: OverflowTooltipType) => {
  const { children, show, title, ...other } = props;
  const { classes } = useStyles();
  const tooltipDivRef = useRef<HTMLDivElement | any>(null);
  const [hoverStatus, setHoverStatus] = useState(false);

  const compareSize = () => {
    if (!show) {
      const compare =
        tooltipDivRef.current.scrollWidth > tooltipDivRef.current.clientWidth;
      setHoverStatus(compare);
    } else {
      setHoverStatus(true);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <Tooltip arrow disableHoverListener={!hoverStatus} title={title} {...other}>
      <div
        className={classes.ellipsis}
        data-testid="childrenDiv"
        ref={tooltipDivRef}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
