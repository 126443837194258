import { Breadcrumbs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

import CrumbLink from "./CrumbLink";

const useStyles = makeStyles()((theme: any) => ({
  centerSmall: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  crumbs: {
    color: "rgba(0, 0, 0, 0.35)",
    marginBottom: theme.spacing(1),
  },
  root: {
    width: "100%",
  },
  listItem: {
    maxWidth: "40ch",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "30ch",
    },
  },
}));

const getCrumbs = (crumbArray: any) => {
  return crumbArray.map((crumb: any, index: number) => (
    <CrumbLink key={index} label={crumb.label} to={crumb.to} />
  ));
};

interface ComponentPropsType {
  crumbs: {
    label: string;
    to?: string;
  }[];
}

const SweptCrumbs = (props: ComponentPropsType) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { crumbs } = props;
  const isMobileWidth = theme.breakpoints.up("xs");

  return (
    <Breadcrumbs
      separator="››"
      className={classes.crumbs}
      classes={{
        root: classes.root,
        li: classes.listItem,
        ol: classes.centerSmall,
      }}
      itemsBeforeCollapse={1}
      itemsAfterCollapse={2}
      maxItems={isMobileWidth ? 3 : 8}
    >
      {getCrumbs(crumbs)}
    </Breadcrumbs>
  );
};

export default SweptCrumbs;
