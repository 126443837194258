import { useState } from "react";

import { Grid, Tooltip } from "@mui/material";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import noData from "../../../assets/images/noData.png";
import { UserType } from "../../../constants";
import {
  apiRequest,
  clearAndLogout,
  formatApiRequestError,
} from "../../../helpers";
import { renderObj, TableToolBarParams } from "../../../types";
import ModalServerError from "../../modals/ModalServerError";
import PageHeader from "../../pageheader/PageHeader";
import OverflowTooltip from "../../shared/OverflowTooltip";
import TableWithStickyHeader from "../../tables/TableWithStickyHeader";
import { reportQueryParams } from "../utils";

const useStyles = makeStyles()((theme) => ({
  percentBlock: {
    marginTop: theme.spacing(1),
  },
}));

const InspectionInstanceReport = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [totalBelowStandard, setTotalBelowStandard] = useState(0);
  const [totalExceedsStandard, setTotalExceedsStandard] = useState(0);
  const [totalInspectionPoints, setTotalInspectionPoints] = useState(0);
  const [totalMeetsStandard, setTotalMeetsStandard] = useState(0);
  const [totalNotInspected, setTotalNotInspected] = useState(0);
  const getTableDataAbortController = new AbortController();

  const displayLocationWithLabel = (
    data: string,
    locationIsActive: boolean
  ) => {
    return `${data}${locationIsActive ? "" : " (Deleted)"}`;
  };

  const userTypeValueToString = (
    value: number,
    isAdmin = false,
    isActive = true,
    returnCleanerString = true,
    returnManagerString = true
  ) => {
    switch (true) {
      case value === UserType.DISABLED:
        return "Deleted";
      case isActive === false:
        return "Inactive";
      case value === UserType.SUPER_ADMIN:
        return "Super Admin";
      case value === UserType.MANAGER && returnManagerString:
        return isAdmin ? "Admin Manager" : "Manager";
      case value === UserType.CLEANER && returnCleanerString:
        return "Cleaner";
      case value === UserType.CLEANER:
        return null;
      case value === UserType.MANAGER:
        return null;
      case value === UserType.CLIENT:
        return "Client";
      case value === UserType.SUPERVISOR:
        return "Supervisor";
      default:
        return "Unknown";
    }
  };

  const displayNameWithLabel = (
    data: string,
    userType: number,
    isUserActive: boolean,
    showManagerLabel = true
  ) => {
    const label = userTypeValueToString(
      userType,
      false,
      isUserActive,
      false,
      showManagerLabel
    );
    return data + (label ? ` (${label})` : "");
  };

  const columns = () => [
    {
      cellContentRenderer: renderName,
      dataKey: "inspectionName",
      label: "Inspection Name",
      showOn: "large" as const,
      width: "20%",
    },
    {
      cellContentRenderer: renderLocationName,
      dataKey: "locationName",
      label: "Location Name",
      showOn: "large" as const,
      width: "20%",
    },
    {
      cellContentRenderer: renderDate,
      dataKey: "completedOn",
      label: "Date",
      showOn: "large" as const,
      width: "13%",
    },
    {
      cellContentRenderer: renderUserName,
      dataKey: "userName",
      label: "Completed By",
      showOn: "large" as const,
      width: "15%",
    },
    {
      align: "right" as const,
      cellContentRenderer: renderPercentExceedsStandard,
      dataKey: "percentExceedsStandard",
      label: "Exceeds",
      showOn: "large" as const,
      total: formatTotalPercent(totalExceedsStandard),
      width: "8%",
    },
    {
      align: "right" as const,
      cellContentRenderer: renderPercentMeetsStandard,
      dataKey: "percentMeetsStandard",
      label: "Meets",
      showOn: "large" as const,
      total: formatTotalPercent(totalMeetsStandard),
      width: "8%",
    },
    {
      align: "right" as const,
      cellContentRenderer: renderPercentBelowStandard,
      dataKey: "percentBelowStandard",
      label: "Below",
      showOn: "large" as const,
      total: formatTotalPercent(totalBelowStandard),
      width: "8%",
    },
    {
      align: "right" as const,
      cellContentRenderer: renderPercentNotInspected,
      dataKey: "percentNotInspected",
      label: "N/A",
      showOn: "large" as const,
      total: formatTotalPercent(totalNotInspected, true),
      width: "8%",
    },
    {
      cellContentRenderer: renderInspectionSmall,
      dataKey: "inspectionName",
      label: "Inspection Name",
      showOn: "small" as const,
      sortKey: "inspection_name",
      width: "100%",
    },
  ];

  const formatTotalPercent = (count: number, isNotInspected = false) => {
    if (!totalInspectionPoints) {
      return "\u2013"; // en-dash
    }
    let percent = Math.floor((count / totalInspectionPoints) * 10000) / 100;
    if (isNotInspected) {
      percent =
        100 -
        Math.floor((totalExceedsStandard / totalInspectionPoints) * 10000) /
          100 -
        Math.floor((totalMeetsStandard / totalInspectionPoints) * 10000) / 100 -
        Math.floor((totalBelowStandard / totalInspectionPoints) * 10000) / 100;
    }
    return `${percent.toFixed(2)}%`;
  };

  const getTableData = (options: TableToolBarParams) => {
    setLoading(true);
    apiRequest({
      endpoint: "reports/inspections",
      params: { ...reportQueryParams(options), camel_case: true },
      signal: getTableDataAbortController.signal,
    })
      .then((response: any) => {
        setLoading(false);
        setInspections(response.items);
        setTotalBelowStandard(response.totalBelowStandard);
        setTotalExceedsStandard(response.totalExceedsStandard);
        setTotalInspectionPoints(response.totalInspectionPoints);
        setTotalMeetsStandard(response.totalMeetsStandard);
        setTotalNotInspected(response.totalNotInspected);
      })
      .catch((error) => {
        setLoading(false);
        if (error.status === 404) {
          // The only way to get a 404 on our GET endpoint is to ask for a page that doesn't exist
          navigate(-1);
        } else if (error.status === 401) {
          clearAndLogout(navigate);
        } else if (error.status >= 400) {
          console.warn(
            "Could not fetch inspection instances list:",
            formatApiRequestError(error)
          );
          toggleErrorModal();
        }
      });
  };

  const renderDate = ({ cellData, rowData }: renderObj) => {
    const { locationTimezone } = rowData;
    const userTimezone = moment.tz.guess();
    const dateTimeZoneformat =
      "MMM DD, YYYY" + (userTimezone !== locationTimezone ? " (z)" : "");

    const date = `${moment(cellData)
      .tz(locationTimezone)
      .format(dateTimeZoneformat)}`;

    return (
      <OverflowTooltip title={date}>
        <span>{date}</span>
      </OverflowTooltip>
    );
  };

  const renderInspectionSmall = ({ cellData, rowData }: renderObj) => {
    return (
      <>
        <div>{renderName({ cellData, rowData })}</div>
        <div>
          <span>
            {renderLocationName({ cellData: rowData.locationName, rowData })}
          </span>
        </div>
        <div>
          {renderName({
            cellData: `Completed by ${rowData.userName}`,
            rowData,
          })}
        </div>
        <div>{renderDate({ cellData: rowData.completedOn, rowData })}</div>
        <div className={classes.percentBlock}>
          <div>
            {renderPercentExceedsStandard({
              cellData: rowData.percentExceedsStandard,
              rowData,
            })}
            {" Exceeds Standards"}
          </div>
          <div>
            {renderPercentMeetsStandard({
              cellData: rowData.percentMeetsStandard,
              rowData,
            })}
            {" Meets Standards"}
          </div>
          <div>
            {renderPercentBelowStandard({
              cellData: rowData.percentBelowStandard,
              rowData,
            })}
            {" Below Standards"}
          </div>
          <div>
            {renderPercentNotInspected({
              cellData: rowData.percentNotInspected,
              rowData,
            })}
            {" Not Inspected"}
          </div>
        </div>
      </>
    );
  };

  const renderLocationName = ({ cellData, rowData }: renderObj) => {
    const { isLocationActive } = rowData;
    const locationName = displayLocationWithLabel(
      String(cellData),
      isLocationActive
    );
    return (
      <OverflowTooltip title={String(cellData)}>
        <span> {locationName}</span>
      </OverflowTooltip>
    );
  };

  const renderName = ({ cellData, rowData }: renderObj) => {
    return (
      <OverflowTooltip title={cellData}>
        <span>{cellData}</span>
      </OverflowTooltip>
    );
  };

  const renderPercentBelowStandard = ({ cellData, rowData }: renderObj) => {
    return renderPercentage(
      Number(cellData),
      rowData.totalBelowStandard,
      rowData.totalInspectionPoints
    );
  };

  const renderPercentExceedsStandard = ({ cellData, rowData }: renderObj) => {
    return renderPercentage(
      Number(cellData),
      rowData.totalExceedsStandard,
      rowData.totalInspectionPoints
    );
  };

  const renderPercentMeetsStandard = ({ cellData, rowData }: renderObj) => {
    return renderPercentage(
      Number(cellData),
      rowData.totalMeetsStandard,
      rowData.totalInspectionPoints
    );
  };

  const renderPercentNotInspected = ({ cellData, rowData }: renderObj) => {
    return renderPercentage(
      Number(cellData),
      rowData.totalNotInspected,
      rowData.totalInspectionPoints
    );
  };

  const renderPercentage = (
    percentage: number,
    count: number,
    total: number
  ) => {
    const tooltip = `${percentage}% (${count}/${total} points)`;
    return (
      <Tooltip title={tooltip}>
        <span>{percentage}%</span>
      </Tooltip>
    );
  };

  const renderUserName = ({ cellData, rowData }: renderObj) => {
    const { userType, isUserActive } = rowData;
    const displayName = displayNameWithLabel(
      String(cellData),
      userType,
      isUserActive,
      false
    );
    return (
      <OverflowTooltip title={displayName}>
        <span>{displayName}</span>
      </OverflowTooltip>
    );
  };

  const toggleErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };

  return (
    <>
      <PageHeader title="Inspections Report" divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableWithStickyHeader
            loading={loading}
            loadingSubtitle={"We are gathering your inspection data."}
            loadingTitle={"Just a moment..."}
            toolbarProps={{
              label: "All Inspections",
              searchInput: true,
            }}
            tableProps={{
              columns: columns(),
              emptyStateProps: {
                title: "No completed inspections... yet!",
                subtitle:
                  "Reach out to your cleaning company to learn more about inspections and quality control.",
                image: noData,
                imageAltText: "No inspections available",
              },
              getTableData: getTableData,
              tableRows: inspections,
              onRowClick: (row) => navigate(`/inspections/${row.id}`),
            }}
          />
        </Grid>
      </Grid>

      <ModalServerError onDismiss={toggleErrorModal} open={showErrorModal} />
    </>
  );
};

export default InspectionInstanceReport;
