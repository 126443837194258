import { createContext } from "react";

import { saveAs } from "file-saver";
import moment, { Moment } from "moment-timezone";

import { CLIENT_LOGIN_METHOD } from "./constants";
import {
  ApiDownloadType,
  ApiRequestContentType,
  ApiRequestType,
  ErrorType,
} from "./types";

const apiRequest = ({
  method = "GET",
  endpoint,
  params = {},
  body = {},
  signal,
  contentType = "application/json",
}: ApiRequestType) => {
  const options: ApiRequestContentType = {
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  if (contentType !== "multipart/form-data") {
    options.headers["Content-Type"] = contentType;
  }

  if (Object.keys(params).length > 0) {
    endpoint += "?" + encodeURLParams(params);
  }

  if (contentType === "multipart/form-data") {
    options.body = body;
  } else if (method !== "GET" && body) {
    options.body = JSON.stringify(body);
  }

  if (signal) {
    options.signal = signal;
  }

  const apiUrl = `${process.env.REACT_APP_API_URL}/${endpoint}`;
  return new Promise((resolve, reject) => {
    fetch(apiUrl, options)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            return resolve(null);
          }
          return resolve(response.json());
        }
        if (response.status === 401 && endpoint !== "sign-in") {
          clearAndLogout(customNavigate);
          return;
        }
        return reject(response);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

const apiDownload = async ({
  endpoint,
  params = {},
  mimetype,
  filename,
}: ApiDownloadType) => {
  const options: Pick<ApiRequestContentType, "headers"> = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: mimetype,
    },
  };
  if (Object.keys(params).length > 0) {
    endpoint += "?" + encodeURLParams(params);
  }
  const apiUrl = `${process.env.REACT_APP_API_URL}/${endpoint}`;

  const response = await fetch(apiUrl, options);
  if (response.ok) {
    const saveAsFilename: any = filename
      ? filename
      : response.headers.get("x-suggested-filename");
    const data = await response.blob();
    saveAs(data, saveAsFilename);
  } else {
    alert(`Failed to download file: ${formatApiRequestError(response)}`);
  }
};

const clearAndLogout = (navigate: any) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  redirectToLogout(navigate);
};

const customNavigate = (path: any) =>
  window.location.assign(new URL(path, window.location.origin));

const encodeURLParams = (params: { [key: string]: any }) => {
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map((value: string) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(value);
          })
          .join("&");
      } else {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      }
    })
    .join("&");
};

const formatApiRequestError = (error: ErrorType) => {
  if (error.status) {
    return `${error.status} ${error.statusText} from ${error.url}`;
  }
  return error;
};

const formatMomentWithTimezone = (
  datetime: Moment,
  timezone: string,
  format: string,
  showTimezone = false
) => {
  /*
    helper function to determine if the given datetime's timezone is the same as user's local timezone, then format it.
    If timezones are the same or timezone not provided, convert to local timezone.
    If different, convert to given timezone (also add the timezone abbr)

    We can't just compare the timezone name, since some timezones are effectively
    the same but have different names (e.g., America/Halifax vs America/Moncton).
    Instead, we compare the datetime offsets of each timezone from UTC.
  */
  if (!datetime) {
    return "";
  }

  const deviceTimezone = moment.tz.guess(true);

  const { isDifferentTimezone, timezoneFormat } = formatTimezoneCode(
    timezone,
    deviceTimezone
  );

  const showTimezoneFormat = showTimezone ? timezoneFormat : "";

  const timezoneUsed =
    timezone && isDifferentTimezone ? timezone : deviceTimezone;

  return moment(datetime)
    .tz(timezoneUsed)
    .format(format + showTimezoneFormat);
};

const formatTimezoneCode = (timezone: string, deviceTimezone: string) => {
  const locationUtcOffset = timezone ? moment().tz(timezone).format("Z") : "";
  const deviceUtcOffset = moment().tz(deviceTimezone).format("Z");
  const isDifferentTimezone = locationUtcOffset !== deviceUtcOffset;
  const timezoneFormat = isDifferentTimezone ? " (z)" : "";

  return { isDifferentTimezone, timezoneFormat };
};

const handleSessionCheck = () => {
  apiRequest({
    method: "POST",
    endpoint: "health-check",
    body: { method: CLIENT_LOGIN_METHOD },
  })
    .then(() => {
      return;
    })
    .catch((error) => {
      console.warn("Error checking user session", formatApiRequestError(error));
    });
};

const redirectToLogout = (navigate: any) => {
  setTimeout(() => {
    navigate("/login");
  }, 1000);
};

const resolveBrowserLocale = (defaultLocale = "en") => {
  /*
    Rely on the window.navigator object to determine user locale
    from http://blog.ksol.fr/user-locale-detection-browser-javascript/
  */
  const { language } = window.navigator;
  return (language || defaultLocale).split("-")[0];
};

const sanitizeAscii = (text: string) => {
  return text
    .replace(/[^\u0020-\u007E]/g, "-")
    .replace(/ /g, "_")
    .toLowerCase();
};

const translateMessage = (
  translatedMessage: any,
  userLanguageSetting: string,
  hasUserTranslationPermission: boolean = true
) => {
  let showTranslateIcon = false;
  let isTranslated = false;
  if (translatedMessage.text) {
    const language = resolveBrowserLocale(userLanguageSetting);
    const hasCustomTranslation = !!translatedMessage.swept_i18n;
    const hasStreamTranslation = !!translatedMessage.i18n;
    const customTranslation = hasCustomTranslation
      ? translatedMessage.swept_i18n[`${language}_text`]
      : null;
    const streamTranslation = hasStreamTranslation
      ? translatedMessage.i18n[`${language}_text`]
      : null;
    if (hasUserTranslationPermission) {
      if (hasCustomTranslation && customTranslation) {
        showTranslateIcon =
          translatedMessage.swept_i18n.language &&
          language !== translatedMessage.swept_i18n.language;
        isTranslated = true;
        translatedMessage.text = customTranslation;
      } else if (hasStreamTranslation && streamTranslation) {
        showTranslateIcon =
          translatedMessage.i18n.language &&
          language !== translatedMessage.i18n.language;
        isTranslated = true;
        translatedMessage.text = streamTranslation;
      } else if (
        (hasCustomTranslation &&
          language !== translatedMessage.swept_i18n.language) ||
        (hasStreamTranslation && language !== translatedMessage.i18n.language)
      ) {
        showTranslateIcon = true;
      }
    }
  }
  return { translatedMessage, showTranslateIcon, isTranslated };
};

const validatePassword = (password: string) => {
  const errors = [];
  if (password.length < 6) {
    errors.push("min_length");
  }

  if (!password.match(/[A-Z]/)) {
    errors.push("upper_case");
  }

  if (!password.match(/[a-z]/)) {
    errors.push("lower_case");
  }

  if (!password.match(/[0-9]/)) {
    errors.push("number");
  }

  // see: https://www.owasp.org/index.php/Password_special_characters
  if (!password.match(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/)) {
    errors.push("symbol");
  }

  return { isValid: errors.length === 0, errors };
};

export type BadgeCountsContextType = { [key: string]: any };
export type UserContextType = { [key: string]: any };

const BadgeCountsContext = createContext<BadgeCountsContextType>({});
const DrawerContext = createContext(true);
const UserContext = createContext<UserContextType>({});

export {
  apiDownload,
  apiRequest,
  clearAndLogout,
  customNavigate,
  encodeURLParams,
  formatApiRequestError,
  formatMomentWithTimezone,
  handleSessionCheck,
  redirectToLogout,
  resolveBrowserLocale,
  sanitizeAscii,
  translateMessage,
  validatePassword,
  BadgeCountsContext,
  DrawerContext,
  UserContext,
};
