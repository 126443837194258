import { TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "tss-react/mui";

import { DRAWER_BREAKPOINT } from "../../constants";
import EndpointTimeoutCard from "../cards/EndpointTimeoutCard";

const useStyles = makeStyles()((theme) => ({
  emptyStateMessage: {
    padding: theme.spacing(5),
    height: "100%",
  },
}));

interface ComponentPropsType {
  columnsLargeLength: number;
  columnsSmallLength: number;
  image: string;
  imageAltText: string;
  link?: {
    label: string;
    to: string;
  };
  title: string;
  subtitle: string;
  filters: boolean;
}

const TableEmptyState = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(DRAWER_BREAKPOINT)
  );
  const {
    columnsLargeLength,
    columnsSmallLength,
    image,
    imageAltText,
    title,
    subtitle,
    link,
    filters,
  } = props;

  return (
    <TableRow>
      <TableCell
        align={"center"}
        colSpan={isSmallScreen ? columnsSmallLength : columnsLargeLength}
        className={classes.emptyStateMessage}
      >
        <EndpointTimeoutCard
          title={
            filters
              ? "We didn't find anything matching that specific request."
              : title
          }
          subtitle={
            filters
              ? "For more results try less specific search and filters."
              : subtitle
          }
          image={!filters && image ? image : ""}
          imageAltText={!filters && imageAltText ? imageAltText : ""}
          link={!filters && link ? link : null}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableEmptyState;
