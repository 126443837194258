import { useEffect, useState } from "react";

import { Box, Button, Dialog, Grid, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import background from "../../assets/images/loginBackground.png";
import logo from "../../assets/images/sweptBlueLogo.png";
import {
  apiRequest,
  customNavigate,
  formatApiRequestError,
} from "../../helpers";
import { validatePasswordHelper } from "../../validationHelpers";
import PasswordFieldWithValidation from "../inputs/PasswordFieldWithValidation";
import DialogActions from "../modals/DialogActions";
import DialogContent from "../modals/DialogContent";
import DialogTitle from "../modals/DialogTitle";

const useStyles = makeStyles()((theme: any) => ({
  root: {
    minHeight: "100vh",
    margin: "0px auto",
    paddingBottom: "10vh",
    paddingTop: "20vh",
    position: "relative",
    width: "100vw",
    justifyContent: "center",
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
      marginBottom: 8,
    },
    "@media (max-width: 600px)": {
      paddingTop: "10vh",
    },
  },
  header: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    height: 64,
    left: "auto",
    padding: "12px 0 0 12px",
    position: "fixed",
    right: 0,
    top: 0,
    width: "100%",
    zIndex: 1201,
    "& img": {
      width: 122,
    },
  },
  gridContainer: {
    width: "100%",
    zIndex: 2,
    placeContent: "start center",
  },
  featureImage: {
    padding: theme.spacing(2),
    "& img": {
      float: "right",
      margin: "0 auto",
      marginRight: "5vw",
      maxWidth: "40vw",
      "@media (max-width: 600px)": {
        float: "none",
        maxWidth: "60vw",
        marginLeft: "calc((100% - 60vw) / 2)",
      },
    },
  },
  forgotPasswordSuccess: {
    maxWidth: 360,
    textAlign: "center",
    color: "#5cc560",
    marginTop: "8vh",
    fontSize: "1.4em",
    lineHeight: "1em",
    "& > span": {
      fontSize: 40,
    },
  },
  form: {
    margin: 0,
    maxWidth: 360,
    "& h3": {
      color: "#04557b",
      fontSize: 44,
      marginBottom: 36,
      marginTop: 24,
    },
    "& h3 ~ p": {
      fontSize: 14,
      marginBottom: 30,
      textAlign: "center",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
    },
    "& .MuiFormLabel-root": {
      textTransform: "capitalize",
    },
    "& .MuiInputLabel-shrink": {
      textTransform: "uppercase",
    },
    "& > button": {
      marginTop: 36,
      padding: 12,
    },
    "& $forgotPasswordSuccess": {},
  },
  link: {
    color: theme.palette.secondary.main,
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  modalContainer: {
    margin: "auto",
    maxWidth: "703px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  versionNumber: {
    position: "absolute",
    bottom: "50px",
    color: theme.typography.h1.color,
    fontSize: "12px",
    textAlign: "center",
    zIndex: 1,
  },
}));

const ResetPage = () => {
  const { classes } = useStyles();
  const [params] = useSearchParams();

  const email = params.get("var1") || "";
  const token = params.get("var2") || "";

  const dataObj = {
    value: "",
    error: false,
    helperText: "",
  };
  const [confirmPassword, setConfirmPassword] = useState({ ...dataObj });
  const [currentYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState({ ...dataObj });
  const [showPasswordSavedScreen, setShowPasswordSavedScreen] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);

  const getTokenAbortController = new AbortController();
  const updatePasswordAbortController = new AbortController();

  useEffect(() => {
    checkPageToken();

    return () => {
      getTokenAbortController.abort();
      updatePasswordAbortController.abort();
    };

    // eslint-disable-next-line
  }, [email, token]);

  const checkPageToken = () => {
    apiRequest({
      method: "GET",
      endpoint: "reset-password",
      params: { username: email, token: token },
      signal: getTokenAbortController.signal,
    })
      .then(() => {})
      .catch((error) => {
        setShowTokenExpiredModal(true);
        console.warn(
          "User ID or token is invalid or expired:",
          formatApiRequestError(error)
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const savePassword = () => {
    if (
      password.error ||
      confirmPassword.error ||
      password.value !== confirmPassword.value
    ) {
      return;
    }

    apiRequest({
      method: "POST",
      endpoint: "reset-password",
      body: {
        password: password.value,
        username: email,
        token: token.substring(1),
      },
      signal: updatePasswordAbortController.signal,
    })
      .then(() => {
        setShowPasswordSavedScreen(true);
      })
      .catch((error) => {
        console.warn(
          "Could not set the user password:",
          formatApiRequestError(error)
        );
      });
  };

  const validatePassword = (event: any) => {
    const newPassword = event.target.value.trim();
    setPassword(validatePasswordHelper(newPassword));
    if (confirmPassword.value !== newPassword) {
      setConfirmPassword({ ...confirmPassword, error: true });
    }
  };

  const validateConfirmPassword = (event: any) => {
    const value = event.target.value.trim();
    const newPassword = {
      ...confirmPassword,
      value,
      error: value !== password.value,
      helperText:
        value !== password.value ? "Must be the same as the password." : "",
    };

    setConfirmPassword(newPassword);
  };

  return (
    <>
      {!isLoading && (
        <Grid container className={classes.root}>
          <Grid className={classes.header} item>
            <img src={logo} alt="Swept logo" />
          </Grid>
          {!showTokenExpiredModal && (
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} sm={6} md={6} className={classes.featureImage}>
                <img
                  src={background}
                  title="Swept Sign-in"
                  alt="Swept Sign-in"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box component="form" className={classes.form}>
                  {!showPasswordSavedScreen && (
                    <>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h3">Password Reset</Typography>
                        <Typography>
                          Please enter your new password below
                        </Typography>
                      </Grid>

                      <PasswordFieldWithValidation
                        id="password"
                        inputId="password"
                        placeholder="Password"
                        inputProps={{ "data-testid": "password" }}
                        label="Your Password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        onChange={validatePassword}
                        error={password.error}
                        helperText={
                          password.error
                            ? "Invalid password: must be 6-85 characters long including an uppercase and lowercase letter, a number and a special character"
                            : ""
                        }
                        fullWidth
                        value={password.value}
                        inviteForm={true}
                        variant="outlined"
                        shrinkLabel={true}
                      />
                      <PasswordFieldWithValidation
                        id="confirm-password"
                        inputId="confirm-password"
                        placeholder="Confirm Password"
                        inputProps={{ "data-testid": "confirm-password" }}
                        label="Confirm Your Password"
                        name="confirmPassword"
                        type="password"
                        autoComplete="confirm-password"
                        onChange={validateConfirmPassword}
                        error={confirmPassword.error}
                        helperText={confirmPassword.helperText}
                        fullWidth
                        value={confirmPassword.value}
                        inviteForm={true}
                        variant="outlined"
                        shrinkLabel={true}
                      />
                      <Button
                        onClick={savePassword}
                        variant="contained"
                        color="secondary"
                        fullWidth
                      >
                        {" "}
                        Submit{" "}
                      </Button>
                    </>
                  )}
                  {showPasswordSavedScreen && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.forgotPasswordSuccess}>
                          <span>✓</span>Your password has been reset
                          successfully. You can <Link href="/">log in</Link>{" "}
                          with the new password.
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid className={classes.versionNumber} item>
            <div>{`© ${currentYear} Swept`}</div>
            <div data-testid="current-app-version">{`${process.env.REACT_APP_SWEPT_VERSION_NAME}`}</div>
          </Grid>
        </Grid>
      )}

      <Dialog
        className={classes.modalContainer}
        fullWidth
        onClose={() => customNavigate("/")}
        open={showTokenExpiredModal}
        PaperProps={{ square: true }}
      >
        <DialogTitle onDismiss={() => customNavigate("/")}>
          Whoops, that's an expired link
        </DialogTitle>
        <DialogContent>
          <Typography>
            For security reasons, password reset links expire after 15 days.
            Please request a new password reset link.
          </Typography>
        </DialogContent>
        <DialogActions
          onDismiss={() => customNavigate("/")}
          closeButtonText={"Okay"}
        />
      </Dialog>
    </>
  );
};

export default ResetPage;
