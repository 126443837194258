import { makeStyles } from "tss-react/mui";

import SweptLink from "../links/SweptLink";
import OverflowTooltip from "../shared/OverflowTooltip";

const useStyles = makeStyles()((theme: any) => ({
  current: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.75)",
    textTransform: "capitalize",
  },
  links: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.35)",
    textDecoration: "underline",
    textTransform: "capitalize",
    "&:hover": {
      color: "#03BEFF",
    },
  },
}));

interface ComponentPropsType {
  label: string;
  to: string;
}

const CrumbLink = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  let { label, to } = props;

  return to ? (
    <SweptLink to={to} className={classes.links}>
      <OverflowTooltip title={label}>{label}</OverflowTooltip>
    </SweptLink>
  ) : (
    <div className={classes.current}>
      <OverflowTooltip title={label}>{label}</OverflowTooltip>
    </div>
  );
};

export default CrumbLink;
