import { Dialog, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import warning from "../../assets/images/warning.png";
import DialogActions from "./DialogActions";
import DialogContent from "./DialogContent";
import DialogTitle from "./DialogTitle";

const useStyles = makeStyles()((theme) => ({
  contentSpacing: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  image: {
    width: "82px",
  },
  modalContainer: {
    margin: "auto",
    maxWidth: "703px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  subheader: {
    color: "rgba(51, 51, 51, 1)",
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(28),
  },
  text: {
    color: "rgba(119, 119, 119)",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
  },
}));

interface ComponentPropsType {
  onDismiss: () => void;
  open: boolean;
  header: string;
  subheader: string;
  details: string;
}

const ModalNotFound = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { onDismiss, open, header, subheader, details } = props;
  return (
    <Dialog
      className={classes.modalContainer}
      onClose={onDismiss}
      aria-labelledby="warning-dialog-title"
      open={open}
      PaperProps={{ square: true }}
    >
      <DialogTitle id="warning-dialog-title">{header}</DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          spacing={4}
        >
          <Grid
            container
            item
            xs={3}
            alignItems="center"
            justifyContent="center"
          >
            <img src={warning} alt="Warning" className={classes.image} />
          </Grid>
          <Grid item xs>
            <Typography
              gutterBottom
              className={classNames(classes.subheader, classes.contentSpacing)}
            >
              {subheader}
            </Typography>
            <Typography
              gutterBottom
              className={classNames(classes.text, classes.contentSpacing)}
            >
              {details}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions onDismiss={onDismiss} closeButtonText="Continue" />
    </Dialog>
  );
};

export default ModalNotFound;
