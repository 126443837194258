import { useContext, useState } from "react";

import { Menu } from "@mui/icons-material";
import { AppBar as MuiAppBar, IconButton, Toolbar } from "@mui/material";
import classNames from "classnames";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import sweptLogo from "../../assets/images/swept.png";
import { UserContext } from "../../helpers";
import { AppbarPropsType } from "../../types";
import AppBarDropdown from "./AppBarDropdown";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    background: theme.palette.background.main,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
  },
  avatar: {
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    margin: "auto",
    cursor: "pointer",
  },
  logoStyle: {
    paddingTop: "4px",
    width: "102px",
  },
  menuButton: {
    borderRadius: 0,
    padding: 0,
    paddingLeft: 16,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  menuImg: {
    height: 64,
    fontSize: "2rem",
    transform: "rotate(0deg)",
    transition: "transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  menuImage: {
    width: 84,
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  toolBar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const AppBarSimple = (props: AppbarPropsType) => {
  const {
    onClickAvatar,
    onLogoutUser,
    onMouseEnteredDropdown,
    onMouseLeftAvatar,
    onMouseLeftDropdown,
    onToggleMobileDrawer,
    showSettingsDropdown,
  } = props;
  const [rotateIcon, setRotateIcon] = useState(false);

  const { classes } = useStyles();
  const { user } = useContext(UserContext);

  const rotateToggleBrowserDrawer = () => {
    setRotateIcon(!rotateIcon);
    onToggleMobileDrawer();
  };

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          id="drawer-toggle-button"
          className={classes.menuButton}
          color="inherit"
          onClick={rotateToggleBrowserDrawer}
        >
          <Menu
            className={classNames(classes.menuImg, {
              [classes.rotateIcon]: rotateIcon,
            })}
            aria-label={"Toggle menu"}
          />
        </IconButton>
        <Link id="app-bar-swept-logo" className={classes.logo} to="/dashboard">
          <img className={classes.logoStyle} src={sweptLogo} alt="Swept" />
        </Link>
        <IconButton
          id="app-bar-avatar"
          onClick={onClickAvatar}
          onMouseLeave={onMouseLeftAvatar}
        >
          <Avatar
            name={user.firstName.split(" ")[0] + " " + user.lastName}
            size="36"
            round
            textSizeRatio={1.7}
            color="#009eef"
            maxInitials={2}
          />
        </IconButton>
      </Toolbar>
      <AppBarDropdown
        onMouseLeftDropdown={onMouseLeftDropdown}
        onMouseEnteredDropdown={onMouseEnteredDropdown}
        showSettingsDropdown={showSettingsDropdown}
        onLogoutUser={onLogoutUser}
      />
    </MuiAppBar>
  );
};

export default AppBarSimple;
