import React from "react";

import { Button, DialogActions } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  green: {
    background: "#00C67F",
    color: "#FFF",
    "&:hover": {
      background: "#47D97A",
    },
    "&:disabled": {
      color: "#FFF",
      opacity: 0.5,
    },
  },
  root: {
    borderTop: theme.divider.default,
    margin: 0,
    padding: theme.spacing(1),
  },
  sticky: {
    backgroundColor: "#FFF",
    bottom: 0,
    position: "sticky",
  },
  actionButton: {
    borderRadius: 0,
    boxShadow: "none",
    marginLeft: theme.spacing(2),
  },
  red: theme.buttons.red,
}));

interface ComponentPropsType {
  acceptButtonColor?: "default" | "green" | "red";
  acceptButtonTestId?: string;
  acceptButtonText?: string;
  closeButtonText?: string;
  disableAccept?: boolean;
  onAccept?: () => void;
  onDismiss?: () => void;
  sticky?: boolean;
  id?: string;
  children?: React.ReactNode;
}

const MuiDialogActions = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const {
    children,
    acceptButtonColor = "default",
    acceptButtonTestId = "",
    acceptButtonText = undefined,
    closeButtonText = "Cancel",
    disableAccept = false,
    onAccept = undefined,
    onDismiss,
    sticky = false,
    id = "dialog-accept-btn",
  } = props;
  return (
    <DialogActions
      className={classNames(classes.root, sticky && classes.sticky)}
    >
      {children}
      <Button onClick={onDismiss} id={`cancel-${id}`}>
        {closeButtonText}
      </Button>
      {acceptButtonText && onAccept && (
        <Button
          color="secondary"
          disabled={disableAccept}
          id={id}
          variant="contained"
          className={classNames(classes.actionButton, {
            [classes.green]: acceptButtonColor === "green",
            [classes.red]: acceptButtonColor === "red",
          })}
          onClick={onAccept}
          data-testid={acceptButtonTestId}
        >
          {acceptButtonText}
        </Button>
      )}
    </DialogActions>
  );
};

export default MuiDialogActions;
