import React, { useContext, useEffect, useRef, useState } from "react";

import { Paper, Table, TableBody } from "@mui/material";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  APPBAR_HEIGHT,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  DRAWER_BREAKPOINT,
} from "../../constants";
import { encodeURLParams, DrawerContext } from "../../helpers";
import TableEmptyState from "./TableEmptyState";
import TableHeader from "./TableHeader";
import TableLoadingState from "./TableLoadingState";
import TableRows from "./TableRows";
import TableStickyHeader from "./TableStickyHeader";
import TableToolbar from "./TableToolbar";

import { columnObj, TableToolbarPropType, TableToolBarParams } from "types";

const useStyles = makeStyles()((theme) => ({
  section: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  table: {
    tableLayout: "fixed",
    maxWidth: `calc(100vw - ${theme.spacing(2)})`,
  },
  tableWidthDrawerCollapsed: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      maxWidth: `calc(100vw - ${DRAWER_WIDTH_COLLAPSED}px - ${theme.spacing(
        8
      )})`,
    },
  },
  tableWidthDrawerExpanded: {
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      maxWidth: `calc(100vw - ${DRAWER_WIDTH_EXPANDED}px - ${theme.spacing(
        8
      )})`,
    },
  },
  tableContainer: {
    borderRadius: "6px",
    marginBottom: theme.spacing(4),
  },
  tableBorderTop: {
    borderTop: theme.divider.default,
  },
  tableRow: {
    cursor: "pointer",
  },
}));

interface ComponentPropsType {
  loading: boolean;
  loadingSubtitle: string | React.ReactNode;
  loadingTitle: string | React.ReactNode;
  refresh?: boolean;
  showHeaderRow?: boolean;
  showStickyHeader?: boolean;
  toolbarProps: TableToolbarPropType;
  tableProps: {
    columns: columnObj[];
    perPage?: number;
    tableRows: any;
    onRowClick?: (row: any) => void;
    rowClassName?: string;
    getTableData: (options: TableToolBarParams) => void;
    emptyStateProps: {
      image: string;
      imageAltText: string;
      title: string;
      subtitle: string;
      link?: {
        label: string;
        to: string;
      };
    };
  };
}

const TableWithStickyHeader = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    loading,
    loadingSubtitle,
    loadingTitle,
    showHeaderRow = true,
    showStickyHeader = true,
    toolbarProps,
    tableProps,
  } = props;

  const {
    columns,
    emptyStateProps,
    getTableData,
    onRowClick,
    rowClassName,
    tableRows,
  } = tableProps;
  const columnsLarge = columns.filter((column) => column.showOn === "large");
  const columnsSmall = columns.filter((column) => column.showOn === "small");

  const [searchText, setSearchText] = useState<string | null>("");
  const [searchTextModified, setSearchTextModified] = useState<string | null>(
    null
  );

  useEffect(() => {
    getTableData(getUrlParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableContainerRef = useRef<HTMLDivElement | any>(null);

  const getTableTop = () => {
    const tableContainer = tableContainerRef.current;
    if (!tableContainer) {
      return 0;
    }

    const tableTop = tableContainer.getBoundingClientRect().top;
    return tableTop + window.scrollY - APPBAR_HEIGHT;
  };

  const getUrlParams = () => {
    const params: any = new URLSearchParams(location.search);

    const searchText = params.get("search") || "";

    setSearchText(searchText);
    setSearchTextModified(null);

    return {
      searchText,
    };
  };

  const handleSearchChange = (newSearch: string) => {
    setSearchTextModified(newSearch);
  };

  const handleSearchSubmit = (newSearch: string) => {
    setSearchTextModified(null);
    setSearchText(newSearch);

    updateUrl({
      searchText: newSearch,
    });
    tableProps.getTableData({
      searchText: newSearch,
    });
    scrollToTopIfNeeded();
  };

  const scrollToTopIfNeeded = () => {
    const tableTop = getTableTop();
    if (window.scrollY > tableTop) {
      window.scrollTo({
        left: 0,
        top: tableTop,
        behavior: "smooth",
      });
    }
  };

  const updatedToolbarProps = {
    ...toolbarProps,
    searchInputProps: {
      label: toolbarProps.label,
      modifiedText: searchTextModified,
      onChange: handleSearchChange,
      onSubmit: handleSearchSubmit,
      submittedText: searchText,
    },
  };

  const updateUrl = ({ page, perPage, searchText }: TableToolBarParams) => {
    const params: any = {};

    if (searchText) {
      params["search"] = searchText;
    }

    const urlEncodedParams = encodeURLParams(params);

    navigate({
      search: `?${urlEncodedParams}`,
    });
  };

  const isBrowserDrawerExpanded = useContext(DrawerContext);
  const filtersApplied = Boolean(searchText);

  return (
    <section className={classes.section}>
      {showStickyHeader && (
        <TableStickyHeader
          columnsLarge={columnsLarge}
          columnsSmall={columnsSmall}
          showHeaderRow={showHeaderRow}
          tableClassName={classNames(
            classes.table,
            isBrowserDrawerExpanded
              ? classes.tableWidthDrawerExpanded
              : classes.tableWidthDrawerCollapsed
          )}
          threshold={getTableTop()}
          toolbarProps={updatedToolbarProps}
        />
      )}
      <Paper className={classes.tableContainer} ref={tableContainerRef}>
        <TableToolbar {...updatedToolbarProps} />
        <Table className={classNames(classes.table, classes.tableBorderTop)}>
          {showHeaderRow && (
            <TableHeader
              columnsLarge={columnsLarge}
              columnsSmall={columnsSmall}
              sticky={false}
            />
          )}
          <TableBody>
            {loading ? (
              <TableLoadingState
                columnsLargeLength={columnsLarge.length}
                columnsSmallLength={columnsSmall.length}
                subtitle={loadingSubtitle}
                title={loadingTitle}
              />
            ) : tableRows && tableRows.length > 0 ? (
              tableRows.map((row: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <TableRows
                      key={`table-row-${row.id ? row.id : index}`}
                      row={row}
                      columnsLarge={columnsLarge}
                      columnsSmall={columnsSmall}
                      onRowClick={onRowClick}
                      rowClassName={rowClassName}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <TableEmptyState
                columnsLargeLength={columnsLarge.length}
                columnsSmallLength={columnsSmall.length}
                filters={filtersApplied}
                {...emptyStateProps}
              />
            )}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
};

export default TableWithStickyHeader;
