import { EMAIL_REGEX, MAX_INPUT_LENGTH } from "./constants";
import { validatePassword } from "./helpers";

const validateEmailHelper = (value: string) => {
  return new Promise(function (resolve) {
    const email = {
      value: value,
      error: false,
      helperText: "",
    };
    if (value) {
      if (value.length > 0 && !EMAIL_REGEX.exec(value)) {
        email.error = true;
        email.helperText = "Invalid email";
      }
      if (value.length > MAX_INPUT_LENGTH) {
        email.error = true;
        email.helperText = "Invalid email: too long";
      }
    }
    resolve(email);
  });
};

const validatePasswordHelper = (value: string) => {
  let password = {
    value: "",
    error: false,
    helperText: "",
  };
  if (value.length > MAX_INPUT_LENGTH) {
    password.error = true;
    password.helperText = "Invalid password: too long";
  } else if (value.length > 0) {
    const isPasswordValid = validatePassword(value).isValid;
    password = {
      value: value,
      error: !isPasswordValid,
      helperText: isPasswordValid ? "" : "Invalid password",
    };
  }
  return password;
};

const validateTextHelper = (name: string, value: string) => {
  const formField = {
    value: value.trim(),
    error: false,
    helperText: "",
  };
  // only white spaces
  if (value.length > 0 && formField.value.length === 0) {
    formField.error = true;
  }
  // text too long for database
  // utf-8 may use up to three bytes per character
  // inputs more than 85 characters long may get truncated in vachar(255) column
  if (value.length > MAX_INPUT_LENGTH) {
    formField.error = true;
    switch (name) {
      case "firstName":
        formField.helperText = "First name";
        break;
      case "lastName":
        formField.helperText = "Last name";
        break;
      case "companyName":
        formField.helperText = "Company name";
        break;
      default:
        formField.helperText = "Text";
    }
    formField.helperText += " too long";
  }
  return formField;
};

export { validateEmailHelper, validatePasswordHelper, validateTextHelper };
