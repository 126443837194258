import React from "react";

import { Translate } from "@mui/icons-material";
import {
  ActionsIcon as DefaultActionsIcon,
  ReactionIcon as DefaultReactionIcon,
  ThreadIcon as DefaultThreadIcon,
  MessageContextValue,
  useMessageContext,
  useUserRole,
  MessageActions,
} from "stream-chat-react";

import {
  DefaultStreamChatGenerics,
  IconProps,
  Capabilities,
} from "../../../../types";
import "./MessageOptions.css";

export type MessageOptionsProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = Partial<
  Pick<MessageContextValue<StreamChatGenerics>, "handleOpenThread">
> & {
  /* Custom component rendering the icon used in message actions button. This button invokes the message actions menu. */
  ActionsIcon?: React.ComponentType<IconProps>;
  /* If true, show the `ThreadIcon` and enable navigation into a `Thread` component. */
  displayReplies?: boolean;
  /* React mutable ref that can be placed on the message root `div` of MessageActions component */
  messageWrapperRef?: React.RefObject<HTMLDivElement>;
  /* Custom component rendering the icon used in a button invoking reactions selector for a given message. */
  ReactionIcon?: React.ComponentType<IconProps>;
  /* Theme string to be added to CSS class names. */
  theme?: string;
  /* Custom component rendering the icon used in a message options button opening thread */
  ThreadIcon?: React.ComponentType<IconProps>;

  handleTranslate: any;
  showTranslateIcon?: boolean;
};

const UnMemoizedMessageOptions = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageOptionsProps<StreamChatGenerics>
) => {
  const {
    ActionsIcon = DefaultActionsIcon,
    displayReplies = true,
    handleOpenThread: propHandleOpenThread,
    messageWrapperRef,
    ReactionIcon = DefaultReactionIcon,
    theme = "simple",
    ThreadIcon = DefaultThreadIcon,
    handleTranslate,
    showTranslateIcon,
  } = props;

  const MESSAGE_ACTIONS = {
    delete: "delete",
    edit: "edit",
    flag: "flag",
    mute: "mute",
    pin: "pin",
    quote: "quote",
    react: "react",
    reply: "reply",
  };

  const {
    customMessageActions,
    handleOpenThread: contextHandleOpenThread,
    initialMessage,
    message,
    onReactionListClick,
    threadList,
  } = useMessageContext<StreamChatGenerics>("MessageOptions");
  const ACTIONS_NOT_WORKING_IN_THREAD = ["pin", "react", "reply"];
  type MessageActionsArray<T extends string = string> = Array<
    | "delete"
    | "edit"
    | "flag"
    | "mute"
    | "pin"
    | "quote"
    | "react"
    | "reply"
    | T
  >;

  const showMessageActionsBox = (
    actions: MessageActionsArray,
    inThread?: boolean | undefined
  ) => {
    if (actions.length === 0) {
      return false;
    }

    if (
      inThread &&
      actions.filter(
        (action) => !ACTIONS_NOT_WORKING_IN_THREAD.includes(action)
      ).length === 0
    ) {
      return false;
    }

    if (
      actions.length === 1 &&
      (actions.includes("react") || actions.includes("reply"))
    ) {
      return false;
    }

    if (
      actions.length === 2 &&
      actions.includes("react") &&
      actions.includes("reply")
    ) {
      return false;
    }

    return true;
  };

  const handleOpenThread = propHandleOpenThread || contextHandleOpenThread;

  const getMessageActions = (
    actions: MessageActionsArray | boolean,
    {
      canDelete,
      canEdit,
      canFlag,
      canMute,
      canPin,
      canQuote,
      canReact,
      canReply,
    }: Capabilities
  ): MessageActionsArray => {
    const messageActionsAfterPermission: MessageActionsArray = [];
    let messageActions: MessageActionsArray = [];

    if (actions && typeof actions === "boolean") {
      // If value of actions is true, then populate all the possible values
      messageActions = Object.keys(MESSAGE_ACTIONS);
    } else if (actions && actions.length > 0) {
      messageActions = [...actions];
    } else {
      return [];
    }

    if (canDelete && messageActions.indexOf(MESSAGE_ACTIONS.delete) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.delete);
    }

    if (canEdit && messageActions.indexOf(MESSAGE_ACTIONS.edit) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.edit);
    }

    if (canFlag && messageActions.indexOf(MESSAGE_ACTIONS.flag) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.flag);
    }

    if (canMute && messageActions.indexOf(MESSAGE_ACTIONS.mute) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.mute);
    }

    if (canPin && messageActions.indexOf(MESSAGE_ACTIONS.pin) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.pin);
    }

    if (canQuote && messageActions.indexOf(MESSAGE_ACTIONS.quote) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.quote);
    }

    if (canReact && messageActions.indexOf(MESSAGE_ACTIONS.react) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.react);
    }

    if (canReply && messageActions.indexOf(MESSAGE_ACTIONS.reply) > -1) {
      messageActionsAfterPermission.push(MESSAGE_ACTIONS.reply);
    }

    return messageActionsAfterPermission;
  };

  const { canReact } = useUserRole(message);

  const messageActions = getMessageActions(["react"], {
    canReact: canReact,
  });
  const showActionsBox =
    showMessageActionsBox(messageActions, threadList) || !!customMessageActions;

  const shouldShowReactions =
    messageActions.indexOf(MESSAGE_ACTIONS.react) > -1;
  const shouldShowReplies =
    messageActions.indexOf(MESSAGE_ACTIONS.reply) > -1 &&
    displayReplies &&
    !threadList;

  if (
    !message.type ||
    message.type === "error" ||
    message.type === "system" ||
    message.type === "ephemeral" ||
    message.status === "failed" ||
    message.status === "sending" ||
    initialMessage
  ) {
    return null;
  }

  const rootClassName = `str-chat__message-${theme}__actions str-chat__message-options`;

  return (
    <div className={rootClassName} data-testid="message-options">
      {shouldShowReactions && (
        <button
          aria-label="Open Reaction Selector"
          className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--reactions str-chat__message-reactions-button`}
          data-testid="message-reaction-action"
          onClick={onReactionListClick}
        >
          <ReactionIcon className="str-chat__message-action-icon" />
        </button>
      )}
      {shouldShowReplies && (
        <button
          aria-label="Open Thread"
          className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--thread str-chat__message-reply-in-thread-button`}
          data-testid="thread-action"
          onClick={handleOpenThread}
        >
          <ThreadIcon className="str-chat__message-action-icon" />
        </button>
      )}
      {showTranslateIcon && (
        <button
          aria-label="Show translation"
          className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--thread str-chat__message-reply-in-thread-button`}
          data-testid="show-translation-action"
          onClick={handleTranslate}
        >
          <Translate
            fontSize="small"
            className="str-chat__message-action-icon"
          />
        </button>
      )}
      {showActionsBox && (
        <MessageActions
          ActionsIcon={ActionsIcon}
          messageWrapperRef={messageWrapperRef}
          getMessageActions={() =>
            getMessageActions(["react"], {
              canReact: canReact,
            })
          }
        />
      )}
    </div>
  );
};

export const MessageOptions = React.memo(
  UnMemoizedMessageOptions
) as typeof UnMemoizedMessageOptions;
