import React, { forwardRef, MouseEventHandler } from "react";

import classNames from "classnames";

import { BoldIcon } from "../../assests/BoldIcon";
import { CodeSnippetIcon } from "../../assests/CodeSnippetIcon";
import { EmojiIcon } from "../../assests/EmojiIcon";
import { ItalicsIcon } from "../../assests/ItalicsIcon";
import { StrikeThroughIcon } from "../../assests/StrikeThroughIcon";
import { MessageInputControlType } from "./hooks/useMessageInputCompositionControls";

type MessageInputControlButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  type: MessageInputControlType;
  active?: boolean;
};

const ControlsIcons: Record<MessageInputControlType, React.ComponentType> = {
  bold: BoldIcon,
  code: CodeSnippetIcon,
  emoji: EmojiIcon,
  italics: ItalicsIcon,
  "strike-through": StrikeThroughIcon,
};

export const MessageInputControlButton = forwardRef<
  HTMLButtonElement,
  MessageInputControlButtonProps
>(({ active, onClick, type }, ref) => {
  const Icon = ControlsIcons[type];

  if (!Icon) return null;

  return (
    <button
      className={classNames("message-input__controls-button", type, {
        active: active,
      })}
      onClick={onClick}
      ref={ref}
    >
      <Icon />
    </button>
  );
});
