import { HashIcon } from "components/messaging/assests/HashIcon";
import "./ChannelEmptyState.css";

export const ChannelEmptyState = () => {
  return (
    <div className="channel-empty__container">
      <HashIcon />
      <p className="channel-empty__first">
        This is the beginning of your chat history
      </p>
      <p className="channel-empty__second">Send messages, photos and links.</p>
    </div>
  );
};
