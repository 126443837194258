import { makeStyles } from "tss-react/mui";

import SweptLink from "../links/SweptLink";

const useStyles = makeStyles()((theme) => ({
  emptyStateImage: {
    marginBottom: theme.spacing(2),
    width: "80%",
    maxWidth: "220px",
  },
  emptyStateSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  emptyStateTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface ComponentPropsType {
  title: string;
  subtitle: string;
  image: string;
  imageAltText: string;
  link?: {
    label: string;
    to: string;
  } | null;
}

const EndpointTimeoutCard = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { title, subtitle, image, imageAltText, link } = props;

  return (
    <>
      {image && imageAltText && (
        <img
          className={classes.emptyStateImage}
          src={image}
          alt={imageAltText}
        />
      )}

      <div className={classes.emptyStateTitle}>{title}</div>
      <div className={classes.emptyStateSubtitle}>
        {subtitle}
        {link && (
          <span>
            {" "}
            <SweptLink to={link.to}>{link.label}</SweptLink>
          </span>
        )}
      </div>
    </>
  );
};

export default EndpointTimeoutCard;
