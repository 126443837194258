import { useContext } from "react";

import { UserContext } from "../../helpers";

const DashBoardPage = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="App">
      <header className="">
        <h1>{user.firstName}</h1>
        <h3>{user.email}</h3>
      </header>
    </div>
  );
};

export default DashBoardPage;
