import { useState, useEffect } from "react";

import { Cancel, CheckCircle, Error, Replay, Stars } from "@mui/icons-material";
import { Button, CircularProgress, Grid, IconButton } from "@mui/material";
import { saveAs } from "file-saver";
import moment, { Moment } from "moment-timezone";
import { useSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FILE_NAME_DATE_FORMAT, InspectionRating } from "../../../constants";
import {
  apiRequest,
  formatApiRequestError,
  sanitizeAscii,
} from "../../../helpers";
import RatingCard from "../../cards/RatingCard";
import ModalNotFound from "../../modals/ModalNotFound";
import ModalServerError from "../../modals/ModalServerError";
import StickyPageHeader from "../../pageheader/StickyPageHeader";
import InspectionAreaCard from "./InspectionAreaCard";
import InspectionDetailsCard from "./InspectionDetailsCard";

const useStyles = makeStyles()((theme) => ({
  button: {
    color: "#757575",
    fontWeight: 600,
  },
  cardSpacing: {
    padding: theme.spacing(1),
  },
  pageBottomMargin: {
    marginBottom: theme.spacing(4),
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const InspectionInstanceDetails = () => {
  const { inspectionId } = useParams();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getInspectionDetailsAbortController = new AbortController();
  const postInspectionPdfAbortController = new AbortController();

  const [disableDownload, setDisableDownload] = useState(false);
  const [inspectionName, setInspectionName] = useState("");
  const [loading, setLoading] = useState(true);
  const [locationName, setLocationName] = useState("");
  const [locationTimezone, setLocationTimezone] = useState("");
  const [completedOn, setCompletedOn] = useState<Moment | null>(null);
  const [completedBy, setCompletedBy] = useState("");
  const [isProcessingPdf, setIsProcessingPdf] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);
  const [inspectionAreas, setInspectionAreas] = useState<any>([]);
  const [percentBelowStandard, setPercentBelowStandard] = useState(0);
  const [percentMeetsStandard, setPercentMeetsStandard] = useState(0);
  const [percentExceedsStandard, setPercentExceedsStandard] = useState(0);
  const [percentNotInspected, setPercentNotInspected] = useState(0);
  const [reportUrl, setReportUrl] = useState<string | null>(null);
  const [totalBelowStandard, setTotalBelowStandard] = useState(0);
  const [totalExceedsStandard, setTotalExceedsStandard] = useState(0);
  const [totalInspectionPoints, setTotalInspectionPoints] = useState(0);
  const [totalMeetsStandard, setTotalMeetsStandard] = useState(0);
  const [totalNotInspected, setTotalNotInspected] = useState(0);

  useEffect(() => {
    if (isNaN(parseInt(inspectionId!, 10))) {
      toggleNotFoundModal();
    } else {
      getInspectionDetails();
    }
    return () => {
      getInspectionDetailsAbortController.abort();
      postInspectionPdfAbortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  const setTotals = (inspectionPointInstances: any) => {
    setTotalBelowStandard(
      inspectionPointInstances.filter(
        (item: any) =>
          item.rating === InspectionRating.BELOW_STANDARD && !item.is_header
      ).length
    );
    setTotalExceedsStandard(
      inspectionPointInstances.filter(
        (item: any) =>
          item.rating === InspectionRating.EXCEEDS_STANDARD && !item.is_header
      ).length
    );
    setTotalInspectionPoints(
      inspectionPointInstances.filter((item: any) => !item.is_header).length
    );
    setTotalMeetsStandard(
      inspectionPointInstances.filter(
        (item: any) =>
          item.rating === InspectionRating.MEETS_STANDARD && !item.is_header
      ).length
    );
    setTotalNotInspected(
      inspectionPointInstances.filter(
        (item: any) =>
          (item.rating === InspectionRating.NOT_INSPECTED ||
            item.rating === InspectionRating.NOT_SET) &&
          !item.is_header
      ).length
    );
  };

  const toggleErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };

  const toggleNotFoundModal = () => {
    if (showNotFoundModal) {
      navigate(`/inspections`);
    } else {
      setShowNotFoundModal(true);
    }
  };

  const updateInspectionAreas = (inspectionPointInstances: any) => {
    const inspectionItems = inspectionPointInstances.length;
    inspectionPointInstances = inspectionPointInstances.sort(
      (a: any, b: any) => a.index - b.index
    );
    const newInspectionAreas: any[] = [];

    let areaIndex = 0;
    for (let pointIndex = 0; pointIndex < inspectionItems; pointIndex++) {
      const inspectionPoint = inspectionPointInstances[pointIndex];
      if (!inspectionPoint.isHeader) {
        if (newInspectionAreas[areaIndex]) {
          newInspectionAreas[areaIndex].points.push(inspectionPoint);
        } else {
          newInspectionAreas[areaIndex] = {
            id: inspectionPoint.id,
            points: [inspectionPoint],
          };
        }
      } else {
        if (pointIndex > 0) {
          areaIndex++;
        }
        newInspectionAreas[areaIndex] = {
          id: inspectionPoint.id,
          name: inspectionPoint.name,
          points: [],
        };
      }
    }
    setInspectionAreas(newInspectionAreas);

    setLoading(false);
  };

  const getInspectionDetails = () => {
    apiRequest({
      endpoint: `reports/inspections/${inspectionId}`,
      params: { camel_case: true },
      signal: getInspectionDetailsAbortController.signal,
    })
      .then((response: any) => {
        setInspectionName(response.name);
        setLocationName(response.location.name);
        setLocationTimezone(response.location.timezone);
        let completedOn = response.completedOn
          ? moment(response.completedOn)
          : null;
        setCompletedOn(completedOn);
        setCompletedBy(response.user.fullName);
        setIsProcessingPdf(response.isProcessingPdf);
        setPercentBelowStandard(response.pointPercentages.belowStandard);
        setPercentExceedsStandard(response.pointPercentages.exceedsStandard);
        setPercentMeetsStandard(response.pointPercentages.meetsStandard);
        setPercentNotInspected(response.pointPercentages.notInspected);
        setReportUrl(response.reportUrl);
        setTotals(response.inspectionPointInstances);
        updateInspectionAreas(response.inspectionPointInstances);
      })
      .catch((error) => {
        setLoading(false);
        if (error.status === 404) {
          toggleNotFoundModal();
        } else if (error.status >= 400) {
          console.warn(
            "Could not fetch inspection instance details:",
            formatApiRequestError(error)
          );
          toggleErrorModal();
        }
      });
  };

  const downloadInspectionPdf = async (url: string) => {
    const fileName = `inspection_report-${sanitizeAscii(
      inspectionName
    )}-${sanitizeAscii(locationName)}-${
      completedOn
        ? completedOn.tz(locationTimezone).format(FILE_NAME_DATE_FORMAT)
        : "undefined"
    }.pdf`;
    try {
      const response = await fetch(url);
      const data = await response.blob();
      saveAs(data, fileName);
    } catch (error) {
      alert(`Failed to download file: ${error}`);
    }
  };

  const handlePdf = () => {
    if (disableDownload || isProcessingPdf) {
      return;
    }

    if (reportUrl) {
      downloadInspectionPdf(reportUrl);
      return;
    }

    setDisableDownload(true);
    setIsProcessingPdf(true);
    apiRequest({
      method: "POST",
      endpoint: `reports/inspections/${inspectionId}/generate-pdf`,
      signal: postInspectionPdfAbortController.signal,
    })
      .then(() => {
        enqueueSnackbar("Generating PDF, check back in a few minutes.", {
          action: (
            <IconButton
              color="inherit"
              onClick={() => window.location.reload()}
            >
              <Replay />
            </IconButton>
          ),
          persist: true,
          variant: "default",
        });
      })
      .catch((error) => {
        console.warn(
          "Could not download inspection instance PDF:",
          formatApiRequestError(error)
        );
        setDisableDownload(false);
        setIsProcessingPdf(false);
      });
  };

  return (
    <>
      <StickyPageHeader
        title={`Inspection Details${
          inspectionName ? ` (${inspectionName})` : ""
        }`}
        crumbs={[
          { label: "Inspections Report", to: "/inspections" },
          { label: "Inspection Details" },
        ]}
      >
        <Button onClick={() => navigate(-1)} className={classes.button}>
          Cancel
        </Button>
        <Button
          onClick={handlePdf}
          color="secondary"
          variant="contained"
          disabled={disableDownload || isProcessingPdf}
        >
          {isProcessingPdf
            ? "Generating PDF"
            : reportUrl
            ? "Download PDF"
            : "Request PDF"}
        </Button>
      </StickyPageHeader>
      {loading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <Grid className={classes.pageBottomMargin} container>
          <Grid container justifyContent="space-between" item xs={12}>
            <Grid className={classes.cardSpacing} item xs={12} sm={6} lg={3}>
              <RatingCard
                description="%"
                gradient="linear-gradient(to right, #7E57C2 0%, #1D557B 100%)"
                icon={<Stars />}
                rating={percentExceedsStandard}
                title="Exceeds Standards"
                tooltip={`${totalExceedsStandard}/${totalInspectionPoints} points`}
              />
            </Grid>
            <Grid className={classes.cardSpacing} item xs={12} sm={6} lg={3}>
              <RatingCard
                description="%"
                gradient="linear-gradient(to right, #00C67F 0%, #175D48 100%)"
                icon={<CheckCircle />}
                rating={percentMeetsStandard}
                title="Meets Standards"
                tooltip={`${totalMeetsStandard}/${totalInspectionPoints} points`}
              />
            </Grid>
            <Grid className={classes.cardSpacing} item xs={12} sm={6} lg={3}>
              <RatingCard
                description="%"
                gradient="linear-gradient(to right, #FF6666 0%, #803333 100%)"
                icon={<Error />}
                rating={percentBelowStandard}
                title="Below Standards"
                tooltip={`${totalBelowStandard}/${totalInspectionPoints} points`}
              />
            </Grid>
            <Grid className={classes.cardSpacing} item xs={12} sm={6} lg={3}>
              <RatingCard
                description="%"
                gradient="linear-gradient(to right, #9E9E9E 0%, #455A64 100%)"
                icon={<Cancel />}
                rating={percentNotInspected}
                title="Not Inspected"
                tooltip={`${totalNotInspected}/${totalInspectionPoints} points`}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12} md={4} direction="column">
              <Grid className={classes.cardSpacing} item>
                <InspectionDetailsCard
                  locationName={locationName}
                  locationTimezone={locationTimezone}
                  completedOn={completedOn}
                  completedBy={completedBy}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={8} direction="column">
              {inspectionAreas &&
                inspectionAreas.map((area: any) => (
                  <Grid key={area.id} className={classes.cardSpacing} item>
                    <InspectionAreaCard {...area} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <ModalNotFound
        open={showNotFoundModal}
        onDismiss={toggleNotFoundModal}
        header={"Cannot access inspection details"}
        details={
          "Either you’re trying to access an inspection you don’t have permission to see, or an inspection that doesn’t actually exist. Hitting continue will redirect you back to the inspections report."
        }
        subheader={"Hang on, something isn’t right here."}
      />
      <ModalServerError onDismiss={toggleErrorModal} open={showErrorModal} />
    </>
  );
};

export default InspectionInstanceDetails;
