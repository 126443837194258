import { useContext } from "react";

import { Forum, ContentPasteSearch } from "@mui/icons-material";
import {
  Badge,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  APPBAR_HEIGHT,
  DRAWER_BREAKPOINT,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
} from "../constants";
import { UserContext } from "../helpers";
import { drawerCollapse, drawerExpansion } from "../theme";
import DrawerItem from "./DrawerItem";

const useStyles = makeStyles()((theme) => ({
  appBarMargin: {
    marginTop: APPBAR_HEIGHT,
  },
  badge: {
    backgroundColor: "red",
    borderRadius: "4px",
    color: "white",
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
    lineHeight: 1,
    padding: "2px 5px",
    position: "absolute",
    top: "2px",
    left: "2px",
    right: "auto",
    width: "auto",
    height: "auto",
  },
  company: {
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    fontSize: theme.typography.pxToRem(16),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  companyText: {
    color: theme.typography.h1.color,
    fontWeight: 700,
    textAlign: "center",
  },
  drawerCollapse: {
    transition: drawerCollapse.transitionWidth,
    width: DRAWER_WIDTH_COLLAPSED,
  },
  drawerExpand: {
    transition: drawerExpansion.transitionWidth,
    width: DRAWER_WIDTH_EXPANDED,
  },
  drawerMobile: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  drawerPaper: {
    border: "none",
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerWidth: {
    width: 200,
    display: "inherit",
    flexShrink: 1,
    background: theme.palette.background.grey,
  },
  image: {
    width: 20,
    height: 20,
  },
  list: {
    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  },
  logoContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up(DRAWER_BREAKPOINT)]: {
      cursor: "pointer", // onClick is set only on the large screen
    },
    marginLeft: 24,
    paddingRight: "35px",
    borderRight: "1px solid #797979",
  },
  logo: {
    width: 122,
  },
  subList: {
    padding: 0,
  },
  trial: {
    padding: "15px 30px",
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
}));

interface ComponentPropsType {
  badgeCounts: number;
  isBrowserDrawerExpanded: boolean;
  isMobileDrawerOpen: boolean;
  onClose: Function;
}

const Drawer = (props: ComponentPropsType) => {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(DRAWER_BREAKPOINT));
  const { classes } = useStyles();
  const { badgeCounts, isBrowserDrawerExpanded, isMobileDrawerOpen, onClose } =
    props;

  const closeMobileDrawer =
    isMobileDrawerOpen && !isLargeScreen ? onClose : () => {};
  return (
    <MuiDrawer
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerWidth]: isLargeScreen,
          [classes.drawerExpand]: isBrowserDrawerExpanded && isLargeScreen,
          [classes.drawerCollapse]: !isBrowserDrawerExpanded && isLargeScreen,
        }),
      }}
      open={isMobileDrawerOpen}
      onClose={() => onClose()}
      variant={isLargeScreen ? "permanent" : "persistent"}
    >
      {!isLargeScreen && (
        <List
          className={classNames(
            classes.list,
            classes.appBarMargin,
            classes.drawerMobile
          )}
        >
          <ListItem classes={{ root: classes.company }}>
            <ListItemText
              primary={user.company.name}
              className={classes.companyText}
              disableTypography={true}
            />
          </ListItem>
        </List>
      )}
      <List
        className={classNames(classes.list, {
          [classes.appBarMargin]: isLargeScreen,
        })}
      >
        <DrawerItem
          id="messages"
          icon={
            <Badge
              badgeContent={badgeCounts}
              classes={{ badge: classes.badge }}
            >
              <Forum />
            </Badge>
          }
          isDrawerOpen={isBrowserDrawerExpanded || isMobileDrawerOpen}
          text="Messages"
          data-testid="messages"
          to="/messaging"
          isSelected={Boolean(
            location.pathname.match("/messaging") || location.pathname === "/"
          )}
          onClick={closeMobileDrawer}
        />
        <DrawerItem
          id="inspections"
          icon={<ContentPasteSearch />}
          isDrawerOpen={isBrowserDrawerExpanded || isMobileDrawerOpen}
          text="Inspections"
          data-testid="inspections"
          to="/inspections"
          isSelected={Boolean(location.pathname.match("/inspections"))}
          onClick={closeMobileDrawer}
        />
      </List>
    </MuiDrawer>
  );
};

export default Drawer;
