import React from "react";

import { DialogContent } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    marginTop: theme.spacing(2.5),
  },
}));

interface ComponentPropsType {
  children?: React.ReactNode;
}

const MuiDialogContent = (props: ComponentPropsType) => {
  const { classes } = useStyles();
  const { children, ...rest } = props;
  return (
    <DialogContent className={classes.root} {...rest}>
      {children}
    </DialogContent>
  );
};

export default MuiDialogContent;
