export const FileIcon = () => (
  <div style={{ display: "flex", marginLeft: "5px" }}>
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 16.25C2.07725 16.25 0.5 14.5153 0.5 12.125V5.75C0.5 2.75675 2.5955 0.5 5.375 0.5C8.1545 0.5 10.25 2.75675 10.25 5.75V11H8.75V5.75C8.75 3.57725 7.33025 2 5.375 2C3.41975 2 2 3.57725 2 5.75V12.125C2 13.4323 2.696 14.75 4.25 14.75C5.804 14.75 6.5 13.4323 6.5 12.125V6.5C6.5 6.0485 6.3905 5 5.375 5C4.3595 5 4.25 6.0485 4.25 6.5V11.75H2.75V6.5C2.75 4.70525 3.80525 3.5 5.375 3.5C6.94475 3.5 8 4.70525 8 6.5V12.125C8 14.5153 6.42275 16.25 4.25 16.25Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  </div>
);
