import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";

import SearchField from "../inputs/SearchField";

import { TableToolbarPropType } from "types";

const useStyles = makeStyles()((theme) => ({
  addButton: {
    alignSelf: "center",
    paddingRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  borderBottom: {
    borderBottom: theme.divider.default,
  },
  borderBottomSticky: {
    borderBottom: theme.divider.default,
    maxWidth: "100vw",
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  borderLeft: {
    borderLeft: theme.divider.default,
  },
  iconButtonWidth: {
    minWidth: "64px",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  itemGrow: {
    flexGrow: 2,
  },
  itemStretch: {
    alignSelf: "stretch",
  },
  itemUnsetGrow: {
    flexGrow: "unset",
  },
  paddingSide: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fixedHeight: {
    height: "64px",
  },
  searchWidth: {
    minWidth: "200px",
  },
  tableTitle: {
    color: theme.palette.text.grey,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tableToolBar: {
    margin: 0,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
  },
}));

const TableToolbar = (props: TableToolbarPropType) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const {
    disabled = true,
    label,
    searchInput,
    searchInputProps,
    sticky,
  } = props;
  const id = `table-toolbar${sticky ? "-sticky" : ""}`;

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.tableToolBar}
    >
      <Grid
        item
        xs={12}
        sm
        className={classNames(
          classes.fixedHeight,
          {
            [classes.paddingSide]: !searchInput,
            [classes.borderBottom]: isSmallScreen && !sticky,
            [classes.borderBottomSticky]: isSmallScreen && sticky,
          },
          classes.itemStretch,
          classes.itemGrow,
          classes.searchWidth
        )}
        data-testid={`${id}-search-input`}
      >
        {searchInput && searchInputProps ? (
          <SearchField
            disabled={disabled}
            placeholder={label}
            {...searchInputProps}
          />
        ) : (
          <Typography
            className={classNames(classes.itemContainer, classes.tableTitle)}
            variant="h6"
          >
            {label}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

TableToolbar.defaultProps = {
  dateRangePicker: false,
  disabled: false,
  downloadButton: false,
  searchInput: true,
  sticky: false,
};

export default TableToolbar;
