import { useContext } from "react";

import { Chat } from "stream-chat-react";

import "stream-chat-react/dist/css/v2/index.css";
import { UserContext } from "../../../../helpers";
import { ChannelContainer } from "../ChannelContainer/ChannelContainer";
import { ChannelListContainer } from "../ChannelListContainer/ChannelListContainer";
import "./MessageInbox.css";

const MessageInbox = ({ client }: any) => {
  const { user } = useContext(UserContext);
  const filters = {
    type: "team",
    team: `company-${user.company.id}`,
    members: { $in: [user.id.toString()] },
  };
  const options = { state: true };
  const sort = { unread_count: -1 };
  return (
    <div className="app__wrapper">
      <Chat client={client} theme="team">
        <ChannelListContainer
          {...{
            filters,
            options,
            sort,
          }}
        />
        <ChannelContainer />
      </Chat>
    </div>
  );
};

export default MessageInbox;
