export const STREAM_CONNECTION_TIMEOUT = 10 * 1000;

export const STREAM_SERVICE_TIMEOUT = 10 * 60 * 1000;

export const TIMER_DEBOUNCE_TIME = 1000;

// Stream defaults are currently being used in place of ACCEPT_FILE_TYPES
export const ACCEPT_FILE_TYPES = [
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

export const MAX_FILE_SIZE = 100 * 1000 * 1000; // maximum attachment upload size for Stream is 100MB.

export const MAX_NUMBER_OF_FILES = 10;

export const CUSTOM_MESSAGE_TYPE = {
  date: "message.date",
  intro: "channel.intro",
} as const;
